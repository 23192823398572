import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/PageHeader';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import PageHeaderNav, { TabItem } from '../../../components/PageHeaderNav';
import { useUserInfo } from '../../../context/UserInfo';
import { routes as localRoutes } from './routes';
import { Route, Switch } from 'react-router-dom';
import RedirectToHome from '../../../components/RedirectToHome';
import {
  ManagerReportsMode,
  useManagerReportsMode,
} from '../../../hooks/useManagerReportsMode';
import { ManagerReportsModeSwitch } from '../../components/ManagerReportsModeSwitch';
import { managerRoutes } from '../../routes';

const teamDevPrefix = 'navigation.menuItems.teamDevelopment';

export const ManagerLearningPage: React.FC = () => {
  const userInfo = useUserInfo();

  const { t } = useTranslation();

  useFlairBreadcrumbHook([
    { label: t(`${teamDevPrefix}.title`) },
    { label: t(`${teamDevPrefix}.subMenuItems.learning.title`) },
  ]);

  const [reportsMode, setReportsMode] = useManagerReportsMode('learning');

  const activeTabs: (TabItem | null)[] = userInfo.isActive
    ? [
        {
          label: t('lms.courses.title'),
          route: managerRoutes.learning.route,
        },
      ]
    : [];

  return (
    <>
      <PageHeader
        title={t(`${teamDevPrefix}.subMenuItems.learning.title`)}
        actions={
          <ManagerReportsModeSwitch
            value={reportsMode}
            onChange={setReportsMode}
          />
        }>
        <PageHeaderNav
          tabItems={activeTabs.filter((x): x is TabItem => x !== null)}
        />
      </PageHeader>
      <Content reportsMode={reportsMode} />
    </>
  );
};

type ContentProps = {
  reportsMode: ManagerReportsMode;
};

const Content: React.FC<ContentProps> = ({ reportsMode }) => (
  <Switch>
    {Object.values(localRoutes()).map(({ render, route }, i) => (
      <Route
        key={i}
        exact={true}
        path={route.template()}
        render={() => render(reportsMode)}
      />
    ))}
    <Route path="*">
      <RedirectToHome />
    </Route>
  </Switch>
);
