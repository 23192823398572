import React from 'react';
import { routes as r } from '../../../routes';
import { MeCourses } from '../MeCourses';

export const routes = () => ({
  reviews: {
    route: r.performanceReviewLearning.route,
    render: () => <MeCourses />,
  },
});
