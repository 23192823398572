import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AuthenticationOptionsResponse = {
  __typename?: 'AuthenticationOptionsResponse';
  jwt: Maybe<JwtAuthentication>;
  options: Maybe<Array<OrganizationLoginOption>>;
  organization: AuthenticationOrganization;
  saml: Maybe<SamlAuthentication>;
};

export type AuthenticationOrganization = {
  __typename?: 'AuthenticationOrganization';
  instanceName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ChangeEmailEmployeeResponse = {
  __typename?: 'ChangeEmailEmployeeResponse';
  error: Maybe<RegistrationEmployeeError>;
  hasEmailChanged: Scalars['Boolean']['output'];
};

export type EmployeeAuthenticationOptionsError = {
  __typename?: 'EmployeeAuthenticationOptionsError';
  error: EmployeeAuthenticationOptionsErrorType;
};

export enum EmployeeAuthenticationOptionsErrorType {
  EmployeeNotFound = 'EMPLOYEE_NOT_FOUND'
}

export type EmployeeAuthenticationOptionsResult = AuthenticationOptionsResponse | EmployeeAuthenticationOptionsError;

export type ForgotPasswordInput = {
  email: Scalars['String']['input'];
};

export type InstanceAuthenticationOptionsError = {
  __typename?: 'InstanceAuthenticationOptionsError';
  error: InstanceAuthenticationOptionsErrorType;
};

export enum InstanceAuthenticationOptionsErrorType {
  OrganizationNotFound = 'ORGANIZATION_NOT_FOUND'
}

export type InstanceAuthenticationOptionsResult = AuthenticationOptionsResponse | InstanceAuthenticationOptionsError;

export type JwtAuthentication = {
  __typename?: 'JWTAuthentication';
  enabled: Scalars['Boolean']['output'];
};

export type KioskInfo = {
  __typename?: 'KioskInfo';
  companyLogoUrl: Maybe<Scalars['String']['output']>;
  kioskId: Scalars['String']['output'];
  kioskLanguage: Maybe<Scalars['String']['output']>;
  kioskLoginPin: Scalars['Boolean']['output'];
  kioskLoginQr: Scalars['Boolean']['output'];
  kioskName: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
};

export type KioskInfoInput = {
  kioskSecret: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export enum LoginKioskError {
  EmployeeNotFound = 'EMPLOYEE_NOT_FOUND',
  EmployeeNotRegisteredAtHub = 'EMPLOYEE_NOT_REGISTERED_AT_HUB',
  KioskNotFound = 'KIOSK_NOT_FOUND',
  OrganizationNotFound = 'ORGANIZATION_NOT_FOUND'
}

export type LoginKioskErrorResult = {
  __typename?: 'LoginKioskErrorResult';
  error: LoginKioskError;
};

export type LoginKioskInput = {
  employeePin: Scalars['String']['input'];
  kioskSecret: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type LoginKioskResult = LoginKioskErrorResult | LoginKioskSuccessResult;

export type LoginKioskSuccessResult = {
  __typename?: 'LoginKioskSuccessResult';
  apiToken: Scalars['String']['output'];
  kioskId: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  changeEmployeeEmail: ChangeEmailEmployeeResponse;
  checkEmployeeAuthenticationOptions: EmployeeAuthenticationOptionsResult;
  checkInstanceAuthenticationOptions: InstanceAuthenticationOptionsResult;
  forgotPassword: Maybe<Scalars['String']['output']>;
  login: Maybe<Scalars['String']['output']>;
  loginKiosk: LoginKioskResult;
  register: Maybe<RegisterResponse>;
  resetPassword: Maybe<ResetPasswordResponse>;
};


export type MutationChangeEmployeeEmailArgs = {
  organizationId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationCheckEmployeeAuthenticationOptionsArgs = {
  email: Scalars['String']['input'];
};


export type MutationCheckInstanceAuthenticationOptionsArgs = {
  instanceName: Scalars['String']['input'];
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLoginKioskArgs = {
  input: LoginKioskInput;
};


export type MutationRegisterArgs = {
  organizationId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  organizationId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type OrganizationFeature = {
  __typename?: 'OrganizationFeature';
  Id: Scalars['String']['output'];
};

export type OrganizationInfo = {
  __typename?: 'OrganizationInfo';
  features: Maybe<Array<OrganizationFeature>>;
  flairHubPermissionSet: Scalars['Boolean']['output'];
  salesforceUserId: Maybe<Scalars['String']['output']>;
};

export type OrganizationLoginOption = {
  __typename?: 'OrganizationLoginOption';
  name: Scalars['String']['output'];
  type: OrganizationLoginOptionType;
};

export enum OrganizationLoginOptionType {
  Password = 'PASSWORD',
  Saml = 'SAML'
}

export type Query = {
  __typename?: 'Query';
  getAuthenticationOptions: Maybe<AuthenticationOptionsResponse>;
  getRegistationEmployee: RegistrationEmployeeResponse;
  getResetPasswordEmployee: ResetPasswordEmployeeResponse;
  kioskInfo: Maybe<KioskInfo>;
  organizationInfo: OrganizationInfo;
};


export type QueryGetRegistationEmployeeArgs = {
  organizationId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type QueryGetResetPasswordEmployeeArgs = {
  organizationId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type QueryKioskInfoArgs = {
  input: KioskInfoInput;
};


export type QueryOrganizationInfoArgs = {
  organizationId: Scalars['String']['input'];
};

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  apiToken: Maybe<Scalars['String']['output']>;
  error: Maybe<RegistrationEmployeeError>;
};

export enum RegistrationEmployeeError {
  EmailAlreadyRegistered = 'EMAIL_ALREADY_REGISTERED',
  EmployeeAlreadyRegistered = 'EMPLOYEE_ALREADY_REGISTERED',
  EmployeeNotFound = 'EMPLOYEE_NOT_FOUND',
  OrganizationNotEnabled = 'ORGANIZATION_NOT_ENABLED',
  OrganizationWrongPermissionSet = 'ORGANIZATION_WRONG_PERMISSION_SET'
}

export type RegistrationEmployeeResponse = {
  __typename?: 'RegistrationEmployeeResponse';
  employee: Maybe<Flair__Employee__C>;
  error: Maybe<RegistrationEmployeeError>;
};

export enum ResetPasswordEmployeeError {
  EmployeeNotFound = 'EMPLOYEE_NOT_FOUND',
  OrganizationNotEnabled = 'ORGANIZATION_NOT_ENABLED'
}

export type ResetPasswordEmployeeResponse = {
  __typename?: 'ResetPasswordEmployeeResponse';
  employee: Maybe<Flair__Employee__C>;
  error: Maybe<ResetPasswordEmployeeError>;
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  error: Maybe<ResetPasswordEmployeeError>;
};

export type SamlAuthentication = {
  __typename?: 'SAMLAuthentication';
  name: Scalars['String']['output'];
};

export type Flair__Employee__C = {
  __typename?: 'flair__Employee__c';
  flair__Company_Email__c: Scalars['String']['output'];
  flair__First_Name__c: Scalars['String']['output'];
};

export type ChangeEmployeeEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type ChangeEmployeeEmailMutation = { __typename?: 'Mutation', changeEmployeeEmail: { __typename?: 'ChangeEmailEmployeeResponse', error: RegistrationEmployeeError | null, hasEmailChanged: boolean } };

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: string | null };

export type GetAuthenticationOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthenticationOptionsQuery = { __typename?: 'Query', getAuthenticationOptions: { __typename?: 'AuthenticationOptionsResponse', saml: { __typename?: 'SAMLAuthentication', name: string } | null, jwt: { __typename?: 'JWTAuthentication', enabled: boolean } | null, options: Array<{ __typename?: 'OrganizationLoginOption', name: string, type: OrganizationLoginOptionType }> | null } | null };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: string | null };

export type OrganizationInfoQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type OrganizationInfoQuery = { __typename?: 'Query', organizationInfo: { __typename?: 'OrganizationInfo', salesforceUserId: string | null, flairHubPermissionSet: boolean } };

export type RegisterMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  token: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'RegisterResponse', apiToken: string | null, error: RegistrationEmployeeError | null } | null };

export type FetchRegisterEmployeeQueryVariables = Exact<{
  token: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type FetchRegisterEmployeeQuery = { __typename?: 'Query', getRegistationEmployee: { __typename?: 'RegistrationEmployeeResponse', error: RegistrationEmployeeError | null, employee: { __typename?: 'flair__Employee__c', flair__Company_Email__c: string, flair__First_Name__c: string } | null } };

export type ResetPasswordMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  token: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'ResetPasswordResponse', error: ResetPasswordEmployeeError | null } | null };

export type FetchResetPasswordEmployeeQueryVariables = Exact<{
  token: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type FetchResetPasswordEmployeeQuery = { __typename?: 'Query', getResetPasswordEmployee: { __typename?: 'ResetPasswordEmployeeResponse', error: ResetPasswordEmployeeError | null, employee: { __typename?: 'flair__Employee__c', flair__Company_Email__c: string, flair__First_Name__c: string } | null } };


export const ChangeEmployeeEmailDocument = gql`
    mutation ChangeEmployeeEmail($token: String!, $organizationId: String!) {
  changeEmployeeEmail(organizationId: $organizationId, token: $token) {
    error
    hasEmailChanged
  }
}
    `;
export type ChangeEmployeeEmailMutationFn = Apollo.MutationFunction<ChangeEmployeeEmailMutation, ChangeEmployeeEmailMutationVariables>;

/**
 * __useChangeEmployeeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmployeeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmployeeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmployeeEmailMutation, { data, loading, error }] = useChangeEmployeeEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useChangeEmployeeEmailMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEmployeeEmailMutation, ChangeEmployeeEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeEmployeeEmailMutation, ChangeEmployeeEmailMutationVariables>(ChangeEmployeeEmailDocument, options);
      }
export type ChangeEmployeeEmailMutationHookResult = ReturnType<typeof useChangeEmployeeEmailMutation>;
export type ChangeEmployeeEmailMutationResult = Apollo.MutationResult<ChangeEmployeeEmailMutation>;
export type ChangeEmployeeEmailMutationOptions = Apollo.BaseMutationOptions<ChangeEmployeeEmailMutation, ChangeEmployeeEmailMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const GetAuthenticationOptionsDocument = gql`
    query GetAuthenticationOptions {
  getAuthenticationOptions {
    saml {
      name
    }
    jwt {
      enabled
    }
    options {
      name
      type
    }
  }
}
    `;

/**
 * __useGetAuthenticationOptionsQuery__
 *
 * To run a query within a React component, call `useGetAuthenticationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthenticationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthenticationOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthenticationOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthenticationOptionsQuery, GetAuthenticationOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthenticationOptionsQuery, GetAuthenticationOptionsQueryVariables>(GetAuthenticationOptionsDocument, options);
      }
export function useGetAuthenticationOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthenticationOptionsQuery, GetAuthenticationOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthenticationOptionsQuery, GetAuthenticationOptionsQueryVariables>(GetAuthenticationOptionsDocument, options);
        }
export function useGetAuthenticationOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAuthenticationOptionsQuery, GetAuthenticationOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAuthenticationOptionsQuery, GetAuthenticationOptionsQueryVariables>(GetAuthenticationOptionsDocument, options);
        }
export type GetAuthenticationOptionsQueryHookResult = ReturnType<typeof useGetAuthenticationOptionsQuery>;
export type GetAuthenticationOptionsLazyQueryHookResult = ReturnType<typeof useGetAuthenticationOptionsLazyQuery>;
export type GetAuthenticationOptionsSuspenseQueryHookResult = ReturnType<typeof useGetAuthenticationOptionsSuspenseQuery>;
export type GetAuthenticationOptionsQueryResult = Apollo.QueryResult<GetAuthenticationOptionsQuery, GetAuthenticationOptionsQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password)
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const OrganizationInfoDocument = gql`
    query OrganizationInfo($organizationId: String!) {
  organizationInfo(organizationId: $organizationId) {
    salesforceUserId
    flairHubPermissionSet
  }
}
    `;

/**
 * __useOrganizationInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationInfoQuery(baseOptions: Apollo.QueryHookOptions<OrganizationInfoQuery, OrganizationInfoQueryVariables> & ({ variables: OrganizationInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationInfoQuery, OrganizationInfoQueryVariables>(OrganizationInfoDocument, options);
      }
export function useOrganizationInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationInfoQuery, OrganizationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationInfoQuery, OrganizationInfoQueryVariables>(OrganizationInfoDocument, options);
        }
export function useOrganizationInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationInfoQuery, OrganizationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationInfoQuery, OrganizationInfoQueryVariables>(OrganizationInfoDocument, options);
        }
export type OrganizationInfoQueryHookResult = ReturnType<typeof useOrganizationInfoQuery>;
export type OrganizationInfoLazyQueryHookResult = ReturnType<typeof useOrganizationInfoLazyQuery>;
export type OrganizationInfoSuspenseQueryHookResult = ReturnType<typeof useOrganizationInfoSuspenseQuery>;
export type OrganizationInfoQueryResult = Apollo.QueryResult<OrganizationInfoQuery, OrganizationInfoQueryVariables>;
export const RegisterDocument = gql`
    mutation Register($organizationId: String!, $token: String!, $password: String!) {
  register(organizationId: $organizationId, token: $token, password: $password) {
    apiToken
    error
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const FetchRegisterEmployeeDocument = gql`
    query FetchRegisterEmployee($token: String!, $organizationId: String!) {
  getRegistationEmployee(organizationId: $organizationId, token: $token) {
    error
    employee {
      flair__Company_Email__c
      flair__First_Name__c
    }
  }
}
    `;

/**
 * __useFetchRegisterEmployeeQuery__
 *
 * To run a query within a React component, call `useFetchRegisterEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRegisterEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRegisterEmployeeQuery({
 *   variables: {
 *      token: // value for 'token'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useFetchRegisterEmployeeQuery(baseOptions: Apollo.QueryHookOptions<FetchRegisterEmployeeQuery, FetchRegisterEmployeeQueryVariables> & ({ variables: FetchRegisterEmployeeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchRegisterEmployeeQuery, FetchRegisterEmployeeQueryVariables>(FetchRegisterEmployeeDocument, options);
      }
export function useFetchRegisterEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchRegisterEmployeeQuery, FetchRegisterEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchRegisterEmployeeQuery, FetchRegisterEmployeeQueryVariables>(FetchRegisterEmployeeDocument, options);
        }
export function useFetchRegisterEmployeeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FetchRegisterEmployeeQuery, FetchRegisterEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchRegisterEmployeeQuery, FetchRegisterEmployeeQueryVariables>(FetchRegisterEmployeeDocument, options);
        }
export type FetchRegisterEmployeeQueryHookResult = ReturnType<typeof useFetchRegisterEmployeeQuery>;
export type FetchRegisterEmployeeLazyQueryHookResult = ReturnType<typeof useFetchRegisterEmployeeLazyQuery>;
export type FetchRegisterEmployeeSuspenseQueryHookResult = ReturnType<typeof useFetchRegisterEmployeeSuspenseQuery>;
export type FetchRegisterEmployeeQueryResult = Apollo.QueryResult<FetchRegisterEmployeeQuery, FetchRegisterEmployeeQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($organizationId: String!, $token: String!, $password: String!) {
  resetPassword(
    organizationId: $organizationId
    token: $token
    password: $password
  ) {
    error
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const FetchResetPasswordEmployeeDocument = gql`
    query FetchResetPasswordEmployee($token: String!, $organizationId: String!) {
  getResetPasswordEmployee(organizationId: $organizationId, token: $token) {
    error
    employee {
      flair__Company_Email__c
      flair__First_Name__c
    }
  }
}
    `;

/**
 * __useFetchResetPasswordEmployeeQuery__
 *
 * To run a query within a React component, call `useFetchResetPasswordEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchResetPasswordEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchResetPasswordEmployeeQuery({
 *   variables: {
 *      token: // value for 'token'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useFetchResetPasswordEmployeeQuery(baseOptions: Apollo.QueryHookOptions<FetchResetPasswordEmployeeQuery, FetchResetPasswordEmployeeQueryVariables> & ({ variables: FetchResetPasswordEmployeeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchResetPasswordEmployeeQuery, FetchResetPasswordEmployeeQueryVariables>(FetchResetPasswordEmployeeDocument, options);
      }
export function useFetchResetPasswordEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchResetPasswordEmployeeQuery, FetchResetPasswordEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchResetPasswordEmployeeQuery, FetchResetPasswordEmployeeQueryVariables>(FetchResetPasswordEmployeeDocument, options);
        }
export function useFetchResetPasswordEmployeeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FetchResetPasswordEmployeeQuery, FetchResetPasswordEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchResetPasswordEmployeeQuery, FetchResetPasswordEmployeeQueryVariables>(FetchResetPasswordEmployeeDocument, options);
        }
export type FetchResetPasswordEmployeeQueryHookResult = ReturnType<typeof useFetchResetPasswordEmployeeQuery>;
export type FetchResetPasswordEmployeeLazyQueryHookResult = ReturnType<typeof useFetchResetPasswordEmployeeLazyQuery>;
export type FetchResetPasswordEmployeeSuspenseQueryHookResult = ReturnType<typeof useFetchResetPasswordEmployeeSuspenseQuery>;
export type FetchResetPasswordEmployeeQueryResult = Apollo.QueryResult<FetchResetPasswordEmployeeQuery, FetchResetPasswordEmployeeQueryVariables>;