import React, { useCallback } from 'react';
import { AdjustTimeEntryRequest } from '../../../components/AutoBreaks/shared';
import {
  calculateTimeEntryStats,
  isLegalTimeEntryStats,
} from '../../../components/AutoBreaks/shared/autoBreaksCalculation/helpers';
import { TimeTrackingBreak } from '../../../components/AutoBreaks/suggestBreaks/clockoutBreaksReducer';
import {
  useSuggestBreakRules,
  useTimeTrackingState,
} from '../../../components/AutoBreaks/suggestBreaks/suggestBreakHooks';
import { SuggestBreaksModal } from '../../../components/AutoBreaks/suggestBreaks/SuggestBreaksModal';
import { useAutoBreaksTimeTrackingFeatures } from '../../../components/AutoBreaks/useAutoBreaksTimeTrackingFeatures';
import { useStopTimeTracking } from '../../../hooks/timeTracking';
import { TimeEntryBreakInput } from '../../../__generated__/graphql';

import StopTrackingModal from './StopTimeTrackingModal';

type Props = {
  notes: string | null;
  clockOutDate: Date | null;
  onClose: () => void;
};

export const ClockoutModals: React.FC<Props> = ({
  notes,
  clockOutDate,
  onClose,
}) => {
  const timeTrackingFeatures = useAutoBreaksTimeTrackingFeatures();

  const suggestBreaksVisible =
    timeTrackingFeatures.suggestBreaks || timeTrackingFeatures.deductBreaks;

  const { timeTrackingState, currentTimeEntryId } = useTimeTrackingState();
  const breakRules = useSuggestBreakRules();

  const { stopTimeTracking } = useStopTimeTracking({
    onSuccess: () => {},
  });

  const handleClockoutSubmit = useCallback(
    (notes: string | null) => {
      if (!currentTimeEntryId) {
        return Promise.resolve();
      }
      return stopTimeTracking(
        currentTimeEntryId,
        notes ?? undefined,
        undefined,
      );
    },
    [currentTimeEntryId, stopTimeTracking],
  );

  const handleSubmitSuggestBreakModal = useCallback(
    (notes: string | null, breaks: TimeTrackingBreak[] | null) => {
      if (!currentTimeEntryId) {
        return Promise.resolve();
      }
      return stopTimeTracking(
        currentTimeEntryId,
        notes ?? undefined,
        breaks ? breaks.map(mapToBreakInput) : [],
      );
    },
    [currentTimeEntryId, stopTimeTracking],
  );

  if (!clockOutDate || !currentTimeEntryId) {
    return null;
  }

  if (!suggestBreaksVisible) {
    return (
      <StopTrackingModal
        timeEntryId={currentTimeEntryId}
        onClose={onClose}
        show={clockOutDate !== null}
        onSubmit={handleClockoutSubmit}
        notes={notes}
      />
    );
  }

  if (!currentTimeEntryId || !breakRules || !timeTrackingState) {
    return null;
  }

  const adjustTimeEntryRequest: AdjustTimeEntryRequest = {
    start: timeTrackingState.start,
    end: clockOutDate ?? new Date(),
    breaks: timeTrackingState.breaks.map((x) => ({
      start: x.start,
      end: x.end,
      breakId: x.uniqueId,
    })),
  };

  const timeEntryStats = calculateTimeEntryStats(
    breakRules,
    adjustTimeEntryRequest,
  );

  if (!isLegalTimeEntryStats(timeEntryStats)) {
    return (
      <SuggestBreaksModal
        breakRules={breakRules}
        notes={notes}
        timeTrackingFeatures={timeTrackingFeatures}
        timeTrackingState={timeTrackingState}
        onClose={onClose}
        onProceed={handleSubmitSuggestBreakModal}
      />
    );
  }

  return (
    <StopTrackingModal
      timeEntryId={currentTimeEntryId}
      onClose={onClose}
      show={clockOutDate !== null}
      notes={notes}
      onSubmit={handleClockoutSubmit}
    />
  );
};

const mapToBreakInput = (src: TimeTrackingBreak): TimeEntryBreakInput => ({
  breakId: src.breakId,
  startDatetime: src.start.toISOString(),
  endDatetime: src.end.toISOString(),
});
