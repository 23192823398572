import React from 'react';
import { Theme } from '../../../../../theme';
import { StatusCircle } from '../../../components/StatusCircle';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { CourseStatus } from '../types';

type Props = {
  status: CourseStatus;
};
const i18Prefix = 'lms.courseStatus';
export const CourseStatusComponent: React.FC<Props> = ({ status }) => {
  const t = useNamespacedTranslation(i18Prefix);
  return (
    <div className="d-flex flex-row align-items-center gap-sm-2">
      <StatusCircle color={getItemStatusColor(status)} size={5} />
      <span>{t(`${status}`)}</span>
    </div>
  );
};

export const getItemStatusColor = (status: CourseStatus): string =>
  Theme.course.status[status].color;
