import React, { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';

import {
  ManagerReportsMode,
  mapManagerReportsType,
} from '../../../hooks/useManagerReportsMode';
import { CoursesFilter } from '../../../pages/Lms/CoursesFilter/CoursesFilter';
import {
  CoursesTable_EmployeeCourseFragment,
  useManagerCoursesQuery,
} from '../../../__generated__/graphql';
import { DataWithLoadingState } from '../../../../../utils/dataWithLoadingState';
import ServerError from '../../../../../components/ServerError';
import { CoursesTable } from '../../../pages/Lms/CoursesTable/CoursesTable';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import { CourseItem, Filter } from '../../../pages/Lms/types';
import { mapCourseItem } from '../../../pages/Lms/mappings';
import { isFilterMatch } from '../../../pages/Lms/logic';

type Props = {
  reportsMode: ManagerReportsMode;
};

export const ManagerCourses: React.FC<Props> = ({ reportsMode }) => {
  const [filter, setFilter] = useState<Filter>({
    search: null,
    status: null,
    employeeId: null,
  });

  const { data, loading, error } = useManagerCoursesQuery({
    variables: { managerReportsType: mapManagerReportsType(reportsMode) },
  });

  const items = useMemo(() => {
    if (loading) {
      return 'loading';
    }
    const employeeCourses = data?.manager.employeeCourses;
    if (error || !employeeCourses) {
      return 'error';
    }
    return employeeCourses
      .map(mapCourseItem)
      .filter((item) => isFilterMatch(item, filter));
  }, [data, loading, error, filter]);

  const employeeOptions = getEmployeeOptions(data?.manager.employeeCourses);
  return (
    <Card>
      <Card.Header className="h-auto">
        <CoursesFilter
          filter={filter}
          onChange={setFilter}
          employeeOptions={employeeOptions}
        />
      </Card.Header>
      <Content items={items} />
    </Card>
  );
};

type ContentProps = {
  items: DataWithLoadingState<CourseItem>;
};

const Content: React.FC<ContentProps> = ({ items }) => {
  if (items === 'loading') {
    return <Loading />;
  }
  if (items === 'error') {
    return <ServerError />;
  }
  return (
    <CoursesTable
      items={items}
      employeeColumnVisible={true}
      gotoColumVisible={false}
    />
  );
};

const Loading: React.FC = () => {
  return <SkeletonTable columns={6} rows={5} />;
};

const getEmployeeOptions = (
  courses: readonly CoursesTable_EmployeeCourseFragment[] | undefined,
) => {
  return (
    courses
      ?.map((x) =>
        x.employee ? { label: x.employee.Name, value: x.employee.Id } : null,
      )
      .filter((x) => x !== null) ?? []
  );
};
