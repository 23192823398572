import React, { useMemo } from 'react';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';
import { TableContent } from '../../../manager/components/TableContent';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { CourseItem, CourseStatus } from '../types';
import { CourseStatusComponent } from './CourseStatus';
import FormattedDateTime from '../../../../../components/datetime/FormattedDateTime';
import { GotoCourseButtonConnected } from './GotoCourseButtonConnected';
import { EmployeeWithAvatarAndPopup } from '../../../components/Employee';

type Props = {
  employeeColumnVisible: boolean;
  gotoColumVisible: boolean;
  items: CourseItem[];
};

const tablePrefix = 'lms.coursesTable';
const headersPrefix = `${tablePrefix}.headers`;

export const CoursesTable: React.FC<Props> = ({
  items,
  employeeColumnVisible,
  gotoColumVisible,
}) => {
  const { t } = useTranslation();

  const columns: Column<CourseItem>[] = useMemo(
    () =>
      [
        {
          Header: () => t(`${headersPrefix}.subject`),
          accessor: 'name',
        },
        employeeColumnVisible
          ? {
              Header: () => t(`${headersPrefix}.employee`),
              accessor: 'employee',
              Cell: ({ value }: { value: CourseItem['employee'] }) =>
                value ? <EmployeeWithAvatarAndPopup employee={value} /> : null,
            }
          : null,
        {
          Header: () => t(`${headersPrefix}.status`),
          accessor: 'status',
          Cell: ({ value }: { value: CourseStatus }) => {
            return <CourseStatusComponent status={value} />;
          },
        },
        {
          Header: () => t(`${headersPrefix}.completeProgress`),
          accessor: 'completeProgress',
          Cell: ({ value }: { value: number }) => `${value}%`,
        },
        {
          Header: () => t(`${headersPrefix}.totalSeconds`),
          accessor: 'totalSeconds',
        },
        {
          Header: () => t(`${headersPrefix}.enrolled`),
          accessor: 'enrolled',
          Cell: ({ value }: { value: Date | null }) =>
            value ? (
              <FormattedDateTime dateTime={value} format="short" />
            ) : (
              <span>-</span>
            ),
        },
        {
          Header: () => t(`${headersPrefix}.completed`),
          accessor: 'completed',
          Cell: ({ value }: { value: Date | null }) =>
            value ? (
              <FormattedDateTime dateTime={value} format="short" />
            ) : (
              <span>-</span>
            ),
        },
        gotoColumVisible
          ? {
              Header: () => '',
              accessor: 'id',
              Cell: ({ value }: { value: string }) => (
                <GotoCourseButtonConnected id={value} />
              ),
            }
          : null,
      ].filter((x) => x !== null) as Column<CourseItem>[],
    [t, employeeColumnVisible, gotoColumVisible],
  );
  if (!items.length) {
    return <EmptyStateCardBody title={t(`${tablePrefix}.empty`)} />;
  }

  return <TableContent columns={columns} data={items} />;
};
