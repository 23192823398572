import React, { useState } from 'react';
import { useEmployeeCoursesQuery } from '../../../__generated__/graphql';
import { SkeletonTable } from '../../../components/Skeleton/Table';
import ServerError from '../../../../../components/ServerError';
import { mapCourseItem } from '../mappings';
import { CoursesTable } from '../CoursesTable/CoursesTable';
import { Filter } from '../types';
import { Card } from 'react-bootstrap';
import { CoursesFilter } from '../CoursesFilter/CoursesFilter';
import { isFilterMatch } from '../logic';

type EmployeeCoursesProps = {
  employeeId: string;
  gotoColumVisible: boolean;
};

export const EmployeeCourses: React.FC<EmployeeCoursesProps> = ({
  employeeId,
  gotoColumVisible,
}) => {
  const [filter, setFilter] = useState<Filter>({ search: null, status: null });

  return (
    <Card>
      <Card.Header className="h-auto">
        <CoursesFilter filter={filter} onChange={setFilter} />
      </Card.Header>
      <EmployeeCoursesContent
        employeeId={employeeId}
        filter={filter}
        gotoColumVisible={gotoColumVisible}
      />
    </Card>
  );
};

type EmployeeCoursesContentProps = {
  employeeId: string;
  filter: Filter;
  gotoColumVisible: boolean;
};

const EmployeeCoursesContent: React.FC<EmployeeCoursesContentProps> = ({
  employeeId,
  filter,
  gotoColumVisible,
}) => {
  const { data, loading, error } = useEmployeeCoursesQuery({
    variables: { employeeId },
  });

  if (loading) {
    return <Loading />;
  }
  const employeeCourses = data?.employeeCourses;
  if (error || !employeeCourses) {
    return <ServerError />;
  }

  const items = data.employeeCourses.map(mapCourseItem);

  const filteredItems = items.filter((item) => isFilterMatch(item, filter));
  return (
    <CoursesTable
      items={filteredItems}
      employeeColumnVisible={false}
      gotoColumVisible={gotoColumVisible}
    />
  );
};

const Loading: React.FC = () => {
  return <SkeletonTable columns={6} rows={5} />;
};
