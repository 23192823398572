import React, { createContext, useContext } from 'react';
import {
  DEFAULT_RATING_MAX,
  DEFAULT_RATING_MIN,
} from '../../components/Questionnaire/QuestionnaireInput/questions/Rating';

type EngagementSurveyContextType = {
  ratingMin: number;
  ratingMax: number;
};

const EngagementSurveySettingsContext =
  createContext<EngagementSurveyContextType>({
    ratingMin: DEFAULT_RATING_MIN,
    ratingMax: DEFAULT_RATING_MAX,
  });

export const useEngagementSurveySettings = () =>
  useContext(EngagementSurveySettingsContext);

export const EngagementSurveySettingsProvider: React.FC<
  EngagementSurveyContextType & { children: React.ReactNode }
> = ({ children, ratingMin, ratingMax }) => {
  return (
    <EngagementSurveySettingsContext.Provider value={{ ratingMin, ratingMax }}>
      {children}
    </EngagementSurveySettingsContext.Provider>
  );
};
