import { parseDate } from '../../../../utils/dateUtils';
import { CoursesTable_EmployeeCourseFragment } from '../../__generated__/graphql';
import { CourseItem, CourseStatus } from './types';

export const mapCourseItem = (
  src: CoursesTable_EmployeeCourseFragment,
): CourseItem => {
  const completeProgress = src.flair__Complete_Progress__c ?? 0;
  return {
    id: src.Id,
    name: src.course?.Name ?? '',
    employeeId: src.flair__Employee__c ?? '',
    status: getStatus(completeProgress),
    completeProgress: completeProgress,
    totalSeconds: src.flair__Total_Seconds__c ?? 0,
    enrolled: src.flair__Enrolled_At__c
      ? parseDate(src.flair__Enrolled_At__c)
      : null,
    completed: src.flair__Completed_At__c
      ? parseDate(src.flair__Completed_At__c)
      : null,
    employee: src.employee,
  };
};

const getStatus = (completeProgress: number): CourseStatus => {
  if (completeProgress >= 100) {
    return 'COMPLETED';
  }
  if (completeProgress > 0) {
    return 'IN_PROGRESS';
  }
  return 'NOT_STARTED';
};
