import React, { createContext, useContext } from 'react';
import {
  DEFAULT_RATING_MAX,
  DEFAULT_RATING_MIN,
} from '../../components/Questionnaire/QuestionnaireInput/questions/Rating';

type FeedbackCycleContextType = {
  ratingMin: number;
  ratingMax: number;
};

const FeedbackCycleSettingsContext = createContext<FeedbackCycleContextType>({
  ratingMin: DEFAULT_RATING_MIN,
  ratingMax: DEFAULT_RATING_MAX,
});

export const useFeedbackCycleSettings = () =>
  useContext(FeedbackCycleSettingsContext);

export const FeedbackCycleSettingsProvider: React.FC<
  FeedbackCycleContextType & { children: React.ReactNode }
> = ({ children, ratingMin, ratingMax }) => {
  return (
    <FeedbackCycleSettingsContext.Provider value={{ ratingMin, ratingMax }}>
      {children}
    </FeedbackCycleSettingsContext.Provider>
  );
};
