import { useCallback } from 'react';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { useCreateMyTimeEntryMutation } from '../../__generated__/graphql';
import { useTimeTrackingErrorsAndWarnings } from './useTimeTrackingErrorsAndWarnings';
import { useTimeTrackingGeolocation } from './useTimeTrackingGeolocation';
import { useToasts } from '../../../../context/Toast';

type Result = {
  startTimeTracking: () => Promise<void>;
  loading: boolean;
};

export const useStartTimeTracking = (): Result => {
  const { getTimeTrackingGeolocationInfo, inProgress } =
    useTimeTrackingGeolocation();
  const { addError } = useToasts();

  const errorHandler = useMutationErrorHandler();
  const { showErrorsAndWarningsToasts } = useTimeTrackingErrorsAndWarnings();

  const [startTimeTracking, mutationResult] = useCreateMyTimeEntryMutation({
    update(cache, cacheResult) {
      const createdMyTimeEntry =
        cacheResult.data?.timeTracking.createMyTimeEntry?.me?.currentTimeEntry;
      if (!createdMyTimeEntry) {
        return;
      }
      // Updating timeSheets
      if (createdMyTimeEntry.timeSheetId) {
        const timeSheetCacheId = cache.identify({
          Id: createdMyTimeEntry.timeSheetId,
          __typename: 'MyTimeSheet',
        });
        // I prefer to use evict here, it just force to reread all queries which use timeSheetId.timeEntries
        // Other way is to add newly created object to timeEntries and clarify that we use same fragments
        cache.evict({
          id: timeSheetCacheId,
          fieldName: 'timeEntries',
        });
      }
    },
  });

  const handleStartTracking: () => Promise<void> = useCallback(async () => {
    try {
      const getCoordinatesResult = await getTimeTrackingGeolocationInfo();
      if (!getCoordinatesResult.timeTrackingAllowed) {
        addError(getCoordinatesResult.reason);
        return;
      }
      const response = await startTimeTracking({
        variables:
          getCoordinatesResult.coordinates !== undefined
            ? {
                input: {
                  geolocation: getCoordinatesResult.coordinates,
                },
              }
            : undefined,
      });

      const errors = response.data?.timeTracking.createMyTimeEntry.errors2;
      const warnings = response.data?.timeTracking.createMyTimeEntry.warnings;
      showErrorsAndWarningsToasts({
        errors,
        warnings,
      });
    } catch (error) {
      errorHandler(error);
    }
  }, [
    startTimeTracking,
    errorHandler,
    addError,
    showErrorsAndWarningsToasts,
    getTimeTrackingGeolocationInfo,
  ]);

  return {
    startTimeTracking: handleStartTracking,
    loading: mutationResult.loading || inProgress,
  };
};
