import React from 'react';
import { Employee } from '..';
import { EmployeeCourses } from '../../../pages/Lms';

type Props = {
  employee: Employee;
};

export const EmployeeLearning: React.FC<Props> = ({ employee }) => {
  return <EmployeeCourses employeeId={employee.Id} gotoColumVisible={false} />;
};
