import React, { useState } from 'react';
import {
  EngagementSurveyEmployeeResponseBySurveyIdQuery,
  Maybe,
  QuestionnaireType,
} from '../../../__generated__/graphql';
import { Questionnaire } from '../../../components/Questionnaire';
import { Col, Row } from 'react-bootstrap';
import { AboutSurveyCard } from '../AboutSurveyCard';
import {
  IQuestionAnswer,
  IQuestionnaireQuestion,
} from '../../../components/Questionnaire/QuestionnaireField';
import { QuestionnaireSectionData } from '../../../components/Questionnaire/QuestionnaireSection';
import {
  ChoiceOption,
  IScorecard,
  onUpdateAnswerRet,
  onUpdateNotesRet,
} from '../../../components/Questionnaire/types';
import { useToasts } from '../../../../../context/Toast';
import { useTranslation } from 'react-i18next';
import { convertToString } from '../../../components/Questionnaire/utils';
import StringToHtml from '../../../../../components/StringToHtml';
import { EngagementSurveySettingsProvider } from '../../../context/engagementSurvey/EngagementSurveySettingsContext';

type EngagementSurveyEmployeeResponse =
  EngagementSurveyEmployeeResponseBySurveyIdQuery['engagementSurveyEmployeeResponseBySurveyAndEmployee'];

export type EngagementSurveyEmployeeResponseProps = {
  engagementSurveyEmployeeResponse: NonNullable<EngagementSurveyEmployeeResponse>;
  onUpdateQuestionAnswer: (
    questionInfo: IQuestionAnswer,
    value: string,
  ) => onUpdateAnswerRet;
  onUpdateQuestionNotes: (
    questionInfo: IQuestionAnswer,
    value: string,
  ) => onUpdateNotesRet;
  onSubmit: (responseId: string) => Promise<any>;
  onDismiss: (responseId: string) => Promise<any>;
};

export type ISortableByOrder = {
  flair__Order__c: Maybe<number>;
};
export const sortByOrderField = (a: ISortableByOrder, b: ISortableByOrder) => {
  if (a.flair__Order__c === null || a.flair__Order__c === undefined) {
    return 1;
  }
  if (b.flair__Order__c === null || b.flair__Order__c === undefined) {
    return -1;
  }
  return a.flair__Order__c - b.flair__Order__c;
};

const formatEngagementSurveyAnswerToQuestionnaireAnswer = (
  answer:
    | NonNullable<EngagementSurveyEmployeeResponse>['questionsAnswers'][0]
    | null,
  question: NonNullable<EngagementSurveyEmployeeResponse>['engagementSurvey']['sections'][0]['questions'][0],
) => {
  if (!answer) {
    return '';
  } else {
    switch (question.flair__Type__c) {
      case QuestionnaireType.FreeText: {
        return answer.flair__Free_Text__c;
      }
      case QuestionnaireType.MultipleChoice:
      case QuestionnaireType.Dropdown:
      case QuestionnaireType.SingleChoice: {
        return answer.selectedOptions
          .map((o) => o.flair__Engagement_Survey_Question_Option__c)
          .filter(Boolean)
          .join(';');
      }
      case QuestionnaireType.FreeSlider:
      case QuestionnaireType.Rating: {
        return `${answer.flair__Score__c}`;
      }
      case QuestionnaireType.Scorecard: {
        return convertToString(
          answer.selectedOptions.map(
            (o): IScorecard => ({
              name: o.flair__Engagement_Survey_Question_Option__c,
              score: o.flair__Value__c,
            }),
          ),
        );
      }
      default: {
        return '';
      }
    }
  }
};

export const EngagementSurveyResponseQuestionnaire: React.FC<
  EngagementSurveyEmployeeResponseProps
> = ({
  engagementSurveyEmployeeResponse,
  onUpdateQuestionAnswer,
  onUpdateQuestionNotes,
  onSubmit,
  onDismiss,
}) => {
  const { t } = useTranslation();
  const { addSuccess } = useToasts();

  const isSubmitted = !!engagementSurveyEmployeeResponse.flair__Submitted_At__c;
  const isDismissed = !!engagementSurveyEmployeeResponse.flair__Dismissed_At__c;
  const responseIsOver =
    isSubmitted ||
    isDismissed ||
    engagementSurveyEmployeeResponse.engagementSurvey.deadlineOver;

  const isCompulsoryToAnswer =
    engagementSurveyEmployeeResponse.engagementSurvey.flair__Is_Compulsory__c;

  const sections: QuestionnaireSectionData[] =
    engagementSurveyEmployeeResponse.engagementSurvey.sections.map((s) => {
      const sectionQuestions = [...s.questions].sort(sortByOrderField);

      return {
        sectionId: s.Id,
        sectionName: s.Name,
        readOnly: responseIsOver,
        questions: sectionQuestions.map((q): IQuestionnaireQuestion => {
          const answer =
            engagementSurveyEmployeeResponse.questionsAnswers.find(
              (qa) => qa.flair__Engagement_Survey_Question__c === q.Id,
            ) || null;
          const questionOptions: ChoiceOption[] = [...q.options]
            .sort(sortByOrderField)
            .map((o) => ({
              value: o.Id,
              label: o.Name,
            }));
          return {
            id: q.Id,
            questionText: q.flair__Question_Text__c,
            description: q.flair__Description__c,
            hint: q.flair__Hint__c,
            sectionId: s.Id,
            type: q.flair__Type__c,
            value: formatEngagementSurveyAnswerToQuestionnaireAnswer(answer, q),
            notes: answer?.flair__Comment__c || '',
            isOptional: q.flair__Is_Optional__c,
            isComplete: false, // will be auto-calculated in the <Questionnaire /> level
            requiredChoices: q.options
              .filter((o) => o.flair__Required__c)
              .map((o) => o.Id),
            showOptionalComment: q.flair__Can_comment__c,
            choices: questionOptions,
          };
        }),
      };
    });

  const [sectionsState, setSectionsState] =
    useState<QuestionnaireSectionData[]>(sections);

  const onChange = (sections: QuestionnaireSectionData[]) => {
    setSectionsState(sections);
  };

  const onSubmitInternal = (questions: IQuestionAnswer[]) => {
    return new Promise<void>(function (resolve) {
      onSubmit(engagementSurveyEmployeeResponse.Id).then(() => {
        addSuccess(t('engagementSurvey.questionnaire.submitSuccessMessage'));
        resolve();
      });
    });
  };

  const handleDismiss = (callback?: () => void) => {
    onDismiss(engagementSurveyEmployeeResponse.Id).then(() => {
      !!callback && callback();
    });
  };

  const closeDateString =
    engagementSurveyEmployeeResponse.engagementSurvey.flair__Deadline__c;
  const closingDate = !!closeDateString ? new Date(closeDateString) : null;

  return (
    <>
      <Row>
        <Col xl={8}>
          {engagementSurveyEmployeeResponse.engagementSurvey
            .flair__Long_Introduction__c && (
            <div className="ps-1 pe-4 mb-4">
              <div>
                <StringToHtml
                  text={
                    engagementSurveyEmployeeResponse.engagementSurvey
                      .flair__Long_Introduction__c
                  }
                />
              </div>
            </div>
          )}
          <EngagementSurveySettingsProvider
            ratingMin={
              engagementSurveyEmployeeResponse.engagementSurvey
                .flair__Rating_Min__c
            }
            ratingMax={
              engagementSurveyEmployeeResponse.engagementSurvey
                .flair__Rating_Max__c
            }>
            <Questionnaire
              readOnly={responseIsOver}
              sections={sectionsState}
              onChange={onChange}
              onSubmit={onSubmitInternal}
              onUpdateQuestionAnswer={onUpdateQuestionAnswer}
              onUpdateQuestionNotes={onUpdateQuestionNotes}
              showSubmitButton={!responseIsOver}
              showDismissButton={!isCompulsoryToAnswer}
              onDismiss={handleDismiss}
            />
          </EngagementSurveySettingsProvider>
        </Col>
        <Col xl={4}>
          <AboutSurveyCard
            isAnonymous={
              engagementSurveyEmployeeResponse.engagementSurvey
                .flair__Is_Anonymous__c
            }
            closingDate={closingDate}
            today={new Date()}
          />
        </Col>
      </Row>
    </>
  );
};

export default EngagementSurveyResponseQuestionnaire;
