import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { EmployeeWithAvatar } from './EmployeeWithAvatar';
import { EmployeePopupContent } from './EmployeePopupContent';
import { Employee, EmployeeAdditionalInfo } from './types';
import { EmployeeWithAvatarAndPopup_ColleagueFragment } from '../../__generated__/graphql';

type PropsMapped = Employee & {
  popupInfo?: EmployeeAdditionalInfo;
};

type PropsWithFragment = {
  employee: EmployeeWithAvatarAndPopup_ColleagueFragment;
};

type Props = PropsMapped | PropsWithFragment;

export const EmployeeWithAvatarAndPopup: React.FC<Props> = (props) => {
  const mappedProps =
    'employee' in props ? mapFragmentToProps(props.employee) : props;
  return <EmployeeWithAvatarAndPopupWithMappedProps {...mappedProps} />;
};

const EmployeeWithAvatarAndPopupWithMappedProps: React.FC<PropsMapped> = ({
  popupInfo,
  ...propsRest
}) => {
  if (popupInfo === undefined) {
    return <EmployeeWithAvatar employee={propsRest} />;
  }
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Popover id="employee-avatar-popup">
          <Popover.Body>
            <EmployeePopupContent {...popupInfo} />
          </Popover.Body>
        </Popover>
      }>
      <div>
        <EmployeeWithAvatar employee={propsRest} />
      </div>
    </OverlayTrigger>
  );
};

function mapFragmentToProps(
  src: EmployeeWithAvatarAndPopup_ColleagueFragment,
): PropsMapped {
  const popupInfo = {
    position: src.flair__Position__c ?? undefined,
    department: src.department?.Name ?? undefined,
  };
  return {
    name: src.Name,
    avatarUrl: src.avatar?.url ?? undefined,
    popupInfo: popupInfo,
  };
}
