import React from 'react';
import { Filter, isCourseStatus } from '../types';
import GlobalTableFilter from '../../../components/GlobalTableFilter';
import ClearFiltersLink from '../../../components/ClearFiltersLink';
import SingleFilter from '../../../../../components/SelectFilters/SingleFilter';
import styled from '@emotion/styled';
import { FilterSelectOption } from '../../../../../atomic/molecules/FlairDropdown/types';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { createStatusOptions } from '../logic';

type Props = {
  filter: Filter;
  employeeOptions?: FilterSelectOption[];
  onChange: (filter: Filter) => void;
};

export const CoursesFilter: React.FC<Props> = ({
  filter,
  onChange,
  employeeOptions,
}) => {
  const t = useNamespacedTranslation('lms.coursesTable.filter');

  const handleSearch = (search: string) => {
    onChange({ ...filter, search });
  };

  const handleStatusChanged = (status: string | null) => {
    onChange({ ...filter, status: isCourseStatus(status) ? status : null });
  };

  const handleEmployeeChanged = (employeeId: string | null) => {
    onChange({ ...filter, employeeId });
  };

  const handleClearFilter = () => {
    onChange({ search: null, status: null, employeeId: null });
  };

  return (
    <div className="d-flex gap-3 justify-content-between align-items-center small">
      <div className="d-flex flex-wrap align-items-center">
        <GlobalTableFilter
          value={filter.search || ''}
          placeholder={t('search')}
          onFilterChange={handleSearch}
        />
      </div>
      <div className="d-flex gap-2 flex-wrap align-items-center justify-content-end">
        <span className="d-none me-3 d-md-block">{t('filterBy')}</span>
        {employeeOptions && (
          <SingleFilterStyled
            placeholder={t('employee')}
            isSearchable={true}
            options={employeeOptions}
            value={filter.employeeId ?? null}
            onChange={handleEmployeeChanged}
          />
        )}
        <SingleFilterStyled
          placeholder={t('status')}
          options={createStatusOptions()}
          value={filter.status}
          onChange={handleStatusChanged}
        />
        <ClearFiltersLink onClick={handleClearFilter} />
      </div>
    </div>
  );
};

const SingleFilterStyled = styled(SingleFilter)({
  maxWidth: '12.5rem',
});
