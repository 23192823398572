import { useCallback } from 'react';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { useResumeMyTimeEntryMutation } from '../../__generated__/graphql';
import { useTimeTrackingErrorsAndWarnings } from './useTimeTrackingErrorsAndWarnings';
import { useToasts } from '../../../../context/Toast';
import { useTimeTrackingGeolocation } from './useTimeTrackingGeolocation';

type Result = {
  resumeTimeTracking: () => Promise<void>;
  loading: boolean;
};

export const useResumeTimeTracking = (timeEntryId: string): Result => {
  const [resumeTimeEntry, mutationResult] = useResumeMyTimeEntryMutation({});
  const errorHandler = useMutationErrorHandler();
  const { getTimeTrackingGeolocationInfo, inProgress } =
    useTimeTrackingGeolocation();
  const { addError } = useToasts();

  const { showErrorsAndWarningsToasts } = useTimeTrackingErrorsAndWarnings();

  const handleOnResume = useCallback(async () => {
    try {
      const getCoordinatesResult = await getTimeTrackingGeolocationInfo();
      if (!getCoordinatesResult.timeTrackingAllowed) {
        addError(getCoordinatesResult.reason);
        return;
      }

      const response = await resumeTimeEntry({
        variables: {
          input: {
            Id: timeEntryId,
            geolocation: getCoordinatesResult.coordinates ?? null,
          },
        },
      });

      const errors =
        response.data?.timeTracking.resumeMyTimeEntry.errors2 || [];
      showErrorsAndWarningsToasts({ errors });
    } catch (error) {
      errorHandler(error);
    }
  }, [
    resumeTimeEntry,
    timeEntryId,
    errorHandler,
    showErrorsAndWarningsToasts,
    addError,
    getTimeTrackingGeolocationInfo,
  ]);

  return {
    resumeTimeTracking: handleOnResume,
    loading: mutationResult.loading || inProgress,
  };
};
