import React from 'react';
import { Employee } from '..';
import { managerRoutes } from '../../routes';
import Overview from '../Overview';
import { param, route } from '../../../../../utils/router';
import EmployeeAbsenceCalendar from '../EmployeeAbsenceCalendar';
import EmployeeTimeSheetList from '../EmployeeTimeSheetList';
import EmployeeTimeSheet from '../EmployeeTimeSheet';
import { EmployeeNotes } from '../EmployeeNotes';
import { EmployeePerformaceReview } from '../EmployeePerformanceReview';
import ManagerDashboard from '../../index';
import EmployeeCertificates from '../EmployeeCertificates';
import { ManagerRole } from '../../../__generated__/graphql';
import { EmployeeDocuments } from '../EmployeeDocuments/EmployeeDocuments';
import { managerRolesWhoHasAccessToDocuments } from '../../../pages/Documents2/constants';
import EmployeeHistory from '../EmployeeHistory';
import { EmployeeInventory } from '../EmployeeInventory/EmployeeInventory';
import { EmployeeLearning } from '../EmployeeLearning';

export const employeePageRoutes = () => ({
  absenceCalendar: {
    route: route(
      'manager',
      'employees',
      param('employeeId'),
      'absence-calendar',
    ),
    rolesWithAccessForThatEmployee: [
      ManagerRole.AbsenceApprover,
      ManagerRole.Manager,
      ManagerRole.HrManager,
    ],
    canBeUsedForAsDefaultRoute: true,
    render: (employee: Employee) => (
      <EmployeeAbsenceCalendar employee={employee} />
    ),
  },
  performanceReview: {
    route: route(
      'manager',
      'employees',
      param('employeeId'),
      'performance-review',
    ),
    canBeUsedForAsDefaultRoute: true,
    rolesWithAccessForThatEmployee: [
      ManagerRole.FeedbackManager,
      ManagerRole.Manager,
      ManagerRole.HrManager,
    ],
    render: (employee: Employee) => (
      <EmployeePerformaceReview employee={employee} />
    ),
  },
  managerPerformanceReview: {
    route: route('manager', 'reviews'),
    rolesWithAccessForThatEmployee: [
      ManagerRole.FeedbackManager,
      ManagerRole.Manager,
      ManagerRole.HrManager,
    ],
    render: () => <ManagerDashboard />,
  },
  employeeNotes: {
    route: managerRoutes.employeeNotes.route,
    canBeUsedForAsDefaultRoute: false,
    rolesWithAccessForThatEmployee: [
      ManagerRole.Manager,
      ManagerRole.FeedbackManager,
      ManagerRole.AbsenceApprover,
      ManagerRole.TimeEntryApprover,
      ManagerRole.Compensation,
      ManagerRole.HrManager,
    ],
    render: (employee: Employee) => <EmployeeNotes employee={employee} />,
  },
  employeeDocuments: {
    route: managerRoutes.documents.route,
    canBeUsedForAsDefaultRoute: false,
    rolesWithAccessForThatEmployee: managerRolesWhoHasAccessToDocuments,
    render: () => <EmployeeDocuments />,
  },
  employeeInventory: {
    route: managerRoutes.employeeInventory.route,
    canBeUsedForAsDefaultRoute: false,
    rolesWithAccessForThatEmployee: [
      ManagerRole.Manager,
      ManagerRole.HrManager,
    ],
    render: () => <EmployeeInventory />,
  },
  timeSheet: {
    route: route(
      'manager',
      'employees',
      param('employeeId'),
      'time-sheets',
      param('timeSheetId'),
    ),
    rolesWithAccessForThatEmployee: [
      ManagerRole.TimeEntryApprover,
      ManagerRole.Manager,
    ],
    render: (employee: Employee) => <EmployeeTimeSheet employee={employee} />,
  },
  timeSheetList: {
    route: route('manager', 'employees', param('employeeId'), 'time-sheets'),
    rolesWithAccessForThatEmployee: [
      ManagerRole.TimeEntryApprover,
      ManagerRole.Manager,
      ManagerRole.HrManager,
    ],
    canBeUsedForAsDefaultRoute: true,
    render: (employee: Employee) => (
      <EmployeeTimeSheetList employee={employee} />
    ),
  },
  overview: {
    route: managerRoutes.employeePage.route,
    rolesWithAccessForThatEmployee: [
      ManagerRole.Manager,
      ManagerRole.HrManager,
      ManagerRole.Compensation,
      ManagerRole.SeeEmployeeData,
    ],
    canBeUsedForAsDefaultRoute: true,
    render: (employee: Employee) => <Overview employee={employee} />,
  },
  employeeCertificates: {
    route: route('manager', 'employees', param('employeeId'), 'certificates'),
    rolesWithAccessForThatEmployee: [
      ManagerRole.Manager,
      ManagerRole.HrManager,
    ],
    render: (employee: Employee) => (
      <EmployeeCertificates employee={employee} />
    ),
  },
  employeeLearning: {
    route: route('manager', 'employees', param('employeeId'), 'learning'),
    rolesWithAccessForThatEmployee: [
      ManagerRole.Manager,
      ManagerRole.HrManager,
      ManagerRole.FeedbackManager,
    ],
    render: (employee: Employee) => <EmployeeLearning employee={employee} />,
  },
  employeeHistory: {
    route: route('manager', 'employees', param('employeeId'), 'history'),
    rolesWithAccessForThatEmployee: [
      ManagerRole.Manager,
      ManagerRole.HrManager,
    ],
    render: (employee: Employee) => <EmployeeHistory employee={employee} />,
  },
});
