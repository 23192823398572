import { minutesToMilliseconds } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import { usePauseTimeTracking } from '../../hooks/timeTracking';
import { Working } from '../../hooks/useCurrentTimeTrackingTimeline';
import { ClockoutModals } from '../../pages/TimeTracking/CurrentTimeTracking/ClockoutModals';
import TimeUntilNow from '../../pages/TimeTracking/CurrentTimeTracking/TimeUntilNow';
import AccordionToggleButton from './AccordionToggleButton';
import CardTitle from './CardTitle';

type Props = {
  step: Working;
  notes: string | null;
  lastModifiedDate: string;
  accordionToggleKey: string;
  accordionOpen: boolean;
  accordionToggleOpen: () => void;
};

const CardHeaderWorkingStep: React.FC<Props> = ({
  step,
  notes,
  lastModifiedDate,
  accordionToggleKey,
  accordionOpen,
  accordionToggleOpen,
}) => {
  const { timeEntryId } = step;
  const [showModalClockoutDate, setShowModalClockoutDate] =
    useState<Date | null>(null);
  const { t } = useTranslation();
  const { pauseTimeTracking, loading: pausing } =
    usePauseTimeTracking(timeEntryId);

  const handleCloseModal = useCallback(
    () => setShowModalClockoutDate(null),
    [setShowModalClockoutDate],
  );
  const handleShowModal = useCallback(
    () => setShowModalClockoutDate(new Date()),
    [setShowModalClockoutDate],
  );
  return (
    <>
      <Row className="align-items-center">
        <Col>
          <CardTitle
            title={
              <TimeUntilNow
                date={step.startTime}
                offset={minutesToMilliseconds(step.totalBreakTime)}
              />
            }
            subtitle={t('timeTracking.current.steps.working.workingTime')}
            accordionToggleKey={accordionToggleKey}
            accordionToggleOpen={accordionToggleOpen}
          />
        </Col>

        <Col className="col-auto">
          <Button
            size="sm"
            variant="outline-warning"
            className="lift btn-rounded-circle me-2"
            onClick={pauseTimeTracking}
            icon="pause-outline"
            isProcessing={pausing}
            tooltip={t('timeTracking.current.steps.working.startBreak.tooltip')}
          />
          <Button
            size="sm"
            variant="outline-danger"
            className="lift btn-rounded-circle me-2"
            onClick={handleShowModal}
            icon="stop-outline"
            disabled={pausing}
            tooltip={t('timeTracking.current.steps.working.clockOut.tooltip')}
          />

          <AccordionToggleButton
            eventKey={accordionToggleKey}
            open={accordionOpen}
            onClick={accordionToggleOpen}
          />
        </Col>
      </Row>
      <ClockoutModals
        key={`stop-tracking-modal-${lastModifiedDate}`}
        notes={notes}
        clockOutDate={showModalClockoutDate}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default CardHeaderWorkingStep;
