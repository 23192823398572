import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Button from '../../../../../components/button';
import ServerError from '../../../../../components/ServerError';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { Redirect } from '../../../../../Router';
import { EmployeeFeedbackQuestionnaire } from '../../../components/EmployeeFeedbackQuestionnaire';
import PageHeader from '../../../components/PageHeader';
import { useUserInfo } from '../../../context/UserInfo';
import { routes } from '../../../routes';
import {
  EmployeeFeedbackState,
  FeedbackCycleState,
  MyPerformanceReviewsDocument,
  useSelfPerformanceEvaluationQuery,
  useSubmitSelfPerformanceEvaluationMutation,
} from '../../../__generated__/graphql';
import { visibleForEmployee } from '../EmployeePerformanceReview/EmployeeFeedbacks/utils';
import { GoalsSection } from '../EmployeePerformanceReview/Goals2/GoalsSection';
import FeedbackStatusSection from '../EmployeePerformanceReview/EmployeePerformanceReviewMeeting/FeedbackStatusSection';
import { useEmployeeSignAndFinish } from '../helpers';
import { FeedbackCycleIntroductionText } from './FeedbackCycleIntroductionText';
import { LoadingSelfPerformanceEvaluation } from './LoadingSelfPerformanceEvaluation';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import { EmployeeSatisfactionModal } from './EmployeeSatisfactionModal';
import './styles.css';
import ConfirmButton from '../../../../../components/button/ConfirmButton';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import { useTranslation } from 'react-i18next';

type Params = {
  feedbackId: string;
};

const i18Path = 'employeePage.performanceReview.meeting';

const Content: React.FC<Params> = ({ feedbackId }) => {
  const t = useNamespacedTranslation(i18Path);
  const { t: rootT } = useTranslation();
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [showSatisfactionModal, setShowSatisfactionModal] =
    useState<boolean>(false);
  const { data, loading, error } = useSelfPerformanceEvaluationQuery({
    variables: {
      id: feedbackId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useFlairBreadcrumbHook(
    [
      { label: rootT('navigation.menuItems.personalDevelopment.title') },
      {
        label: rootT('performanceReview.subMenuItems.reviews.title'),
        url: routes.performanceReview.route.create({}),
      },
      { label: data?.me.feedback.feedbackCycle.Name ?? '' },
    ],
    loading,
  );

  const userInfo = useUserInfo();

  const [submitFeedback, { loading: submitting }] =
    useSubmitSelfPerformanceEvaluationMutation({
      awaitRefetchQueries: true,
      /* eslint-disable-next-line no-restricted-syntax */
      refetchQueries: [
        {
          query: MyPerformanceReviewsDocument,
        },
      ],
    });
  const errorHandler = useMutationErrorHandler();
  const [isFinishing, signAndFinish] = useEmployeeSignAndFinish();

  const handleSubmitFeedback = useCallback(async () => {
    await submitFeedback({
      variables: {
        input: {
          id: feedbackId,
        },
      },
    })
      .then()
      .catch(errorHandler);
  }, [submitFeedback, feedbackId, errorHandler]);

  if (error) {
    return <ServerError />;
  }

  if (loading || data === undefined) {
    return <LoadingSelfPerformanceEvaluation />;
  }

  const feedback = data.me.feedback;

  const isSubmitted =
    feedback.flair__State__c === EmployeeFeedbackState.Submitted;
  const managerSubmitted =
    feedback.managerFeedback?.flair__State__c ===
    EmployeeFeedbackState.Submitted;
  const isCycleCompleted =
    feedback.feedbackCycle.flair__State__c !== FeedbackCycleState.InProgress;

  const isAccepted = feedback.outcome.flair__Accepted_At__c; //When the employee Complete or Sign and Finish

  if (isSubmitted && managerSubmitted) {
    return (
      <Redirect
        to={routes.pastPerformanceReview.route}
        feedbackId={feedbackId}
      />
    );
  }

  const {
    flair__Meeting_Required__c: meetingRequired,
    flair__Signature_required__c: signatureRequired,
    flair__Manage_Goals__c: manageGoals,
  } = feedback.feedbackCycle;

  const signAndCompleteBtnLbl = t(
    signatureRequired ? 'signComplete' : 'complete',
  );

  const handleFinish = () => {
    if (signatureRequired && satisfactionQuestion) {
      setShowSatisfactionModal(true);
    } else {
      return signAndFinish(feedback.outcome.Id);
    }
  };
  const satisfactionQuestion = feedback.answers.find(
    (item) => item.question.flair__Is_Satisfaction_Section__c,
  );

  const shouldShowSatisfactionConfirm =
    !isSubmitted && satisfactionQuestion && !signatureRequired;

  const handleCompleteFeedbackCycle = () => {
    if (shouldShowSatisfactionConfirm) {
      setShowSatisfactionModal(true);
    } else {
      handleSubmitFeedback();
    }
  };

  const shouldRenderSignAndCompleteBtn =
    signatureRequired && isSubmitted && !isAccepted && !meetingRequired;

  return (
    <>
      <PageHeader title={feedback.feedbackCycle.Name} />
      {satisfactionQuestion && !isCycleCompleted && !isAccepted && (
        <EmployeeSatisfactionModal
          onSubmit={() =>
            signatureRequired
              ? signAndFinish(feedback.outcome.Id)
              : handleSubmitFeedback()
          }
          answer={satisfactionQuestion}
          visible={showSatisfactionModal}
          isSignStep={false}
          onClose={() => setShowSatisfactionModal(false)}
          isLoading={submitting || isFinishing}
        />
      )}
      <Row>
        <Col
          xl={{ span: 8, order: 1 }}
          xs={{ span: 12, order: 2 }}
          style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 15.5rem)' }}>
          <FeedbackCycleIntroductionText
            feedbackCycle={feedback.feedbackCycle}
          />
          <EmployeeFeedbackQuestionnaire
            answers={feedback.answers.filter(visibleForEmployee)}
            setIsComplete={setIsComplete}
            readOnly={isCycleCompleted}
            isPeerReviewer={false}
            questionsSettings={{
              ratingMin: feedback.feedbackCycle.flair__Rating_Min__c,
              ratingMax: feedback.feedbackCycle.flair__Rating_Max__c,
            }}
          />
        </Col>

        <Col
          xl={{ span: 4, order: 2 }}
          xs={{ span: 12, order: 1 }}
          style={{ position: 'sticky', top: 0, height: '75vh' }}>
          {manageGoals && (
            <GoalsSection
              employeeId={userInfo.id}
              employeeName={userInfo.name}
            />
          )}

          {!meetingRequired && isSubmitted && signatureRequired && (
            <FeedbackStatusSection
              ended={false}
              readonly={false}
              signatureRequired={signatureRequired}
            />
          )}

          {shouldRenderSignAndCompleteBtn && (
            <Row className="submit-button">
              <Col className="text-end">
                <ConfirmButton
                  variant="primary"
                  className="mb-3 w-100"
                  onConfirm={handleFinish}
                  isProcessing={isFinishing}
                  label={signAndCompleteBtnLbl}
                  confirmTitle={t('confirmTitle')}
                  confirmText={t('confirmText')}
                />
              </Col>
            </Row>
          )}

          {!isSubmitted && (
            <Row className="submit-button">
              <Col className="text-end">
                {/*we will show a normal confirm dialog if no need to show the satisfaction question in this step*/}
                {!shouldShowSatisfactionConfirm && (
                  <ConfirmButton
                    variant="primary"
                    disabled={!isComplete}
                    tooltip={
                      !isComplete ? t('pleaseFillRequiredFields') : undefined
                    }
                    className="mb-3 w-100"
                    onConfirm={handleSubmitFeedback}
                    isProcessing={submitting}
                    label={t('submit')}
                    confirmTitle={t('confirmTitle')}
                    confirmText={t('confirmText')}
                  />
                )}
                {shouldShowSatisfactionConfirm && (
                  <Button
                    disabled={!isComplete}
                    className="mb-3 w-100"
                    label={t('submit')}
                    isProcessing={submitting}
                    onClick={handleCompleteFeedbackCycle}
                  />
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export const SelfPerformanceEvaluation: React.FC = () => {
  const { feedbackId } = useParams<Params>();

  return (
    <>
      <Row className="justify-content-center">
        <Col lg={12}>
          <Content feedbackId={feedbackId} />
        </Col>
      </Row>
    </>
  );
};
