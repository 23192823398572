import React from 'react';

import { FeedbackQuestionType } from '../../../__generated__/graphql';

import FreeSliderInput from './questions/FreeSlider';
import DropdownInput from './questions/Dropdown';
import ScorecardInput from './questions/Scorecard';
import FreeTextInput from './questions/FreeText';
import SingleChoiceInput from './questions/SingleChoice';
import MultipleChoiceInput from './questions/MultipleChoice';
import { Rating } from '../../Questionnaire/QuestionnaireInput/questions/Rating';
import { useFeedbackCycleSettings } from '../../../context/feedbackCycle/FeedbackCycleSettingsContext';

export type Props = {
  id: string;
  value: string;
  onChange: (value: string) => void;
  type: FeedbackQuestionType;
  choices: ReadonlyArray<string>;
  notes: string;
  onChangeNotes: (value: string) => void;
  readOnly: boolean;
  requiredChoices: ReadonlyArray<string>;
  showOptionalComment: boolean;
};

export const EmployeeFeedbackInput: React.FC<Props> = ({
  id,
  value,
  onChange,
  type,
  choices,
  notes,
  onChangeNotes,
  readOnly,
  showOptionalComment,
  requiredChoices,
}) => {
  const { ratingMin, ratingMax } = useFeedbackCycleSettings();

  switch (type) {
    case FeedbackQuestionType.FreeText: {
      return (
        <FreeTextInput
          id={id}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
        />
      );
    }
    case FeedbackQuestionType.Rating: {
      return (
        <Rating
          value={value}
          choices={choices}
          onChange={onChange}
          notes={notes}
          onChangeNotes={onChangeNotes}
          readOnly={readOnly}
          showOptionalComment={showOptionalComment}
          ratingMin={ratingMin}
          ratingMax={ratingMax}
        />
      );
    }
    case FeedbackQuestionType.MultipleChoice: {
      return (
        <MultipleChoiceInput
          id={id}
          value={value}
          choices={choices}
          onChange={onChange}
          notes={notes}
          onChangeNotes={onChangeNotes}
          readOnly={readOnly}
          showOptionalComment={showOptionalComment}
        />
      );
    }
    case FeedbackQuestionType.SingleChoice: {
      return (
        <SingleChoiceInput
          id={id}
          value={value}
          choices={choices}
          onChange={onChange}
          notes={notes}
          onChangeNotes={onChangeNotes}
          readOnly={readOnly}
          showOptionalComment={showOptionalComment}
        />
      );
    }
    case FeedbackQuestionType.FreeSlider: {
      return (
        <FreeSliderInput
          id={id}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          notes={notes}
          onChangeNotes={onChangeNotes}
          showOptionalComment={showOptionalComment}
        />
      );
    }
    case FeedbackQuestionType.Dropdown: {
      return (
        <DropdownInput
          id={id}
          value={value}
          choices={choices}
          onChange={onChange}
          notes={notes}
          onChangeNotes={onChangeNotes}
          readOnly={readOnly}
          showOptionalComment={showOptionalComment}
        />
      );
    }
    case FeedbackQuestionType.Scorecard: {
      return (
        <ScorecardInput
          id={id}
          value={value}
          choices={choices}
          onChange={onChange}
          notes={notes}
          onChangeNotes={onChangeNotes}
          readOnly={readOnly}
          showOptionalComment={showOptionalComment}
          requiredChoices={requiredChoices}
        />
      );
    }
    case FeedbackQuestionType.YesNo:
    case FeedbackQuestionType.Unknown: {
      return null;
    }
  }
};
