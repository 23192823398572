import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from '../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import {
  TimeEntryBreakInput,
  useStopMyTimeEntryMutation,
} from '../../__generated__/graphql';
import { useTimeTrackingErrorsAndWarnings } from './useTimeTrackingErrorsAndWarnings';
import { useTimeTrackingGeolocation } from './useTimeTrackingGeolocation';

type Result = {
  stopTimeTracking: (
    timeEntryId: string,
    notes?: string,
    updateBreaks?: TimeEntryBreakInput[],
  ) => Promise<void>;
  loading: boolean;
};

type Args = {
  onSuccess: () => void;
};

export const useStopTimeTracking = ({ onSuccess }: Args): Result => {
  const { t } = useTranslation();
  const [stopTimeEntry, mutationResult] = useStopMyTimeEntryMutation();
  const { addSuccess, addError } = useToasts();
  const errorHandler = useMutationErrorHandler();
  const { showErrorsAndWarningsToasts } = useTimeTrackingErrorsAndWarnings();
  const { getTimeTrackingGeolocationInfo, inProgress } =
    useTimeTrackingGeolocation();

  const handleOnStop = useCallback(
    async (
      timeEntryId: string,
      notes?: string,
      updateBreaks?: TimeEntryBreakInput[],
    ) => {
      try {
        const getCoordinatesResult = await getTimeTrackingGeolocationInfo();
        if (!getCoordinatesResult.timeTrackingAllowed) {
          addError(getCoordinatesResult.reason);
          return;
        }

        const response = await stopTimeEntry({
          variables: {
            input: {
              Id: timeEntryId,
              notes: notes ?? null,
              updateBreaks: updateBreaks ?? null,
              geolocation: getCoordinatesResult.coordinates ?? null,
            },
          },
        });

        const errors = response.data?.timeTracking.stopMyTimeEntry.errors2;
        showErrorsAndWarningsToasts({ errors });
        const autodeducted =
          response.data?.timeTracking.stopMyTimeEntry.record?.breaks.some(
            (x) => x.flair__Auto_adjusted_Datetime__c !== null,
          ) ?? false;
        if (autodeducted) {
          addSuccess(
            t('timeTracking.current.steps.working.clockOut.autodeducted'),
          );
        }
        onSuccess();
      } catch (error) {
        errorHandler(error);
      }
    },
    [
      stopTimeEntry,
      onSuccess,
      errorHandler,
      showErrorsAndWarningsToasts,
      addSuccess,
      addError,
      t,
      getTimeTrackingGeolocationInfo,
    ],
  );

  return {
    stopTimeTracking: handleOnStop,
    loading: mutationResult.loading || inProgress,
  };
};
