import { useCallback, useMemo, useState } from 'react';
import { useTimeTrackingSettings } from '../timeTrackingSettings';
import { getGeolocation } from './geolocation';
import { useTranslation } from 'react-i18next';

type Result = {
  isTrackGeolocationEnabled: boolean;
  inProgress: boolean;
  getTimeTrackingGeolocationInfo: () => Promise<TimeTrackingGeolocationInfo>;
};

export type TimeTrackingGeolocationInfo =
  | {
      timeTrackingAllowed: true;
      coordinates: GeoCoordinates | undefined;
    }
  | {
      timeTrackingAllowed: false;
      reason: string;
    };

export type GeoCoordinates = {
  latitude: number;
  longitude: number;
  accuracy: number;
};

export const useTimeTrackingGeolocation = (): Result => {
  const timeTrackingSettings = useTimeTrackingSettings();
  const { t } = useTranslation();

  const [inProgress, setInProgress] = useState<boolean>(false);
  const isTrackGeolocationEnabled = useMemo(() => {
    if (!timeTrackingSettings || timeTrackingSettings.loading) {
      return false;
    }
    return (
      timeTrackingSettings.timeFramework?.flair__Track_Geolocation__c === true
    );
  }, [timeTrackingSettings]);

  const getTimeTrackingGeolocationInfo =
    useCallback(async (): Promise<TimeTrackingGeolocationInfo> => {
      if (!isTrackGeolocationEnabled) {
        return {
          timeTrackingAllowed: true,
          coordinates: undefined,
        };
      }
      setInProgress(true);
      try {
        const res = await getGeolocation();
        if (res.status === 'permissionDenied') {
          return {
            timeTrackingAllowed: false,
            reason: t(
              'timeTracking.timeTrackingErrors.locationPermissionDenied',
            ),
          };
        }
        return {
          timeTrackingAllowed: true,
          coordinates: res.coordinates
            ? mapCoordinates(res.coordinates)
            : undefined,
        };
      } finally {
        setInProgress(false);
      }
    }, [isTrackGeolocationEnabled, setInProgress, t]);

  return {
    isTrackGeolocationEnabled,
    getTimeTrackingGeolocationInfo,
    inProgress,
  };
};

const mapCoordinates = (
  coordinates: GeolocationCoordinates,
): GeoCoordinates => ({
  latitude: coordinates.latitude,
  longitude: coordinates.longitude,
  accuracy: coordinates.accuracy,
});
