import i18next from 'i18next';
import { allCourseStatuses, CourseItem, Filter } from './types';

export const createStatusOptions = () => {
  return allCourseStatuses.map((x) => ({
    value: x,
    label: i18next.t(`lms.courseStatus.${x}`),
  }));
};

export const isFilterMatch = (item: CourseItem, filter: Filter) => {
  if (filter.search) {
    if (!item.name.toLowerCase().includes(filter.search.toLowerCase())) {
      return false;
    }
  }
  if (filter.status) {
    if (item.status !== filter.status) {
      return false;
    }
  }
  if (filter.employeeId) {
    if (item.employeeId !== filter.employeeId) {
      return false;
    }
  }
  return true;
};
