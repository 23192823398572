import React from 'react';
import { ComparedFeedbackSection } from '../../hooks/useComparedFeedbackSectionsMapper';
import { QuestionLabel } from '../EmployeeFeedback/QuestionLabel';
import { SideBySideAnswers } from '../EmployeeFeedback/SideBySideAnswers';
import { FeedbackCard } from '../EmployeeFeedbackQuestionnaire/EmployeeFeedbackQuestion';
import { EmployeeFeedbackAccordion } from '../EmployeeFeedbackQuestionnaire/EmployeeFeedbackAccordion';
import { Resolution } from './Resolution';
import { Nullable } from '../../../../utils/nullable';
import { FeedbackCycleSettingsProvider } from '../../context/feedbackCycle/FeedbackCycleSettingsContext';
import { FeedbackCycleQuestionsSettings } from '../EmployeeFeedbackQuestionnaire/types';

type Props = {
  employeeId: string;
  managerId: Nullable<string>;
  employeeName: string;
  managerName: Nullable<string>;
  sections: ComparedFeedbackSection[];
  questionsSettings: FeedbackCycleQuestionsSettings;
};

export const EmployeeFeedbackDetails: React.FC<Props> = ({
  employeeId,
  managerId,
  employeeName,
  managerName,
  sections,
  questionsSettings,
}) => {
  return (
    <FeedbackCycleSettingsProvider
      ratingMin={questionsSettings.ratingMin}
      ratingMax={questionsSettings.ratingMax}>
      {sections.map((section) => (
        <EmployeeFeedbackAccordion key={section.name} title={section.name}>
          {section.resolutions.map((resolution) => (
            <FeedbackCard key={resolution.id}>
              <QuestionLabel>{resolution.question.value}</QuestionLabel>
              <SideBySideAnswers
                employeeId={employeeId}
                employeeName={employeeName}
                employeeAnswer={resolution.employeeAnswer}
                managerId={managerId}
                managerName={managerName}
                managerAnswer={resolution.managerAnswer}
                questionVisibility={resolution.question.questionVisibility}
                answerVisibility={resolution.question.answerVisibility}
              />
              <Resolution resolution={resolution} />
            </FeedbackCard>
          ))}
        </EmployeeFeedbackAccordion>
      ))}
    </FeedbackCycleSettingsProvider>
  );
};
