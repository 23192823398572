import React from 'react';
import { ManagerReportsMode } from '../../../hooks/useManagerReportsMode';
import { managerRoutes } from '../../routes';
import { ManagerCourses } from '../ManagerCourses/ManagerCourses';

export const routes = () => ({
  reviews: {
    route: managerRoutes.learning.route,
    render: (reportsMode: ManagerReportsMode) => (
      <ManagerCourses reportsMode={reportsMode} />
    ),
  },
});
