import React, { useMemo } from 'react';
import { Column, Row, SortingRule } from 'react-table';
import {
  TicketAssignee,
  TicketDictionaryItem,
  TicketEmployee,
  TicketsListItem,
  TicketStatus,
} from '../types';
import { Trans, useTranslation } from 'react-i18next';
import { EmptyTickets } from './EmptyTickets';
import { TableContentManual } from '../../../../manager/components/TableContentManual';
import FormattedDate from '../../../../../../components/date/FormattedDate';
import { parseDate } from '../../../../../../utils/dateUtils';
import { EmployeeWithAvatar } from '../../../../components/Employee';
import { TicketItemStatus } from './StatusCell/TicketItemStatus';
import { TicketSubjectCell } from './TicketSubjectCell/TicketSubjectCell';

type Props<T> = {
  hasTicketFilter: boolean;
  pageSize?: number;
  initialSortBy: Array<SortingRule<T>>;
  onClick: (row: TableItem) => void;
  onSortByChanged: (sortBy: SortingRule<T>[]) => void;
  onPageIndexChanged?: (pageIndex: number) => void;
  itemsCount: number | undefined;
  initialPageIndex: number;
  tickets: TicketsListItem[];
  isManager: boolean;
};

export type TableItem = {
  id: string;
  status: TicketStatus;
  ticketNumber: number;
  category: TicketDictionaryItem;
  requested: string;
  assignee: TicketAssignee | null;
  employee: TicketEmployee;
  lastUpdate: string | null;
  subject: TicketDictionaryItem | null;
};

const i18Prefix = 'support.tickets';
export const TicketsTable: React.FC<Props<TicketsListItem>> = ({
  hasTicketFilter,
  pageSize,
  itemsCount,
  initialPageIndex,
  onPageIndexChanged,
  onSortByChanged,
  initialSortBy,
  onClick,
  isManager,
  tickets,
}) => {
  const { t } = useTranslation();

  const items = tickets.map(mapToTableItem);

  const columns: Column<TableItem>[] = useMemo(() => {
    const columnsWithNull: (Column<TableItem> | null)[] = [
      {
        Header: () => (
          <Trans
            t={t}
            i18nKey={`${t(`${i18Prefix}.table.header.ticketNumber`)}`}
          />
        ),
        accessor: 'ticketNumber',
        Cell: ({ value }: { value: number }) => <div>{value}</div>,
      },
      isManager
        ? {
            Header: () => (
              <Trans
                t={t}
                i18nKey={`${t(`${i18Prefix}.table.header.employee`)}`}
              />
            ),
            accessor: 'employee',
            Cell: ({ value }: { value: TicketEmployee }) => (
              <EmployeeWithAvatar
                employee={{
                  name: value?.name,
                  avatarUrl: value?.avatarUrl,
                }}></EmployeeWithAvatar>
            ),
          }
        : null,
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.subject`)}`} />
        ),
        accessor: 'subject',
        Cell: ({
          value,
          row,
        }: {
          value: TicketDictionaryItem;
          row: Row<TableItem>;
        }) => {
          const name = value?.label ?? value?.name ?? '-';
          return (
            <TicketSubjectCell
              onClick={() => onClick(row.original)}
              name={name}
            />
          );
        },
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.category`)}`} />
        ),
        accessor: 'category',
        Cell: ({ value }: { value: TicketDictionaryItem }) => (
          <div>{value.label}</div>
        ),
      },
      {
        Header: () => (
          <Trans
            t={t}
            i18nKey={`${t(`${i18Prefix}.table.header.requested`)}`}
          />
        ),
        accessor: 'requested',
        Cell: ({ value }: { value: string }) => (
          <FormattedDate day={parseDate(value)} format="short" />
        ),
      },
      {
        Header: () => (
          <Trans
            t={t}
            i18nKey={`${t(`${i18Prefix}.table.header.lastUpdate`)}`}
          />
        ),
        accessor: 'lastUpdate',
        Cell: ({ value }: { value: string | null }) =>
          value ? (
            <FormattedDate day={parseDate(value)} format="short" />
          ) : (
            <span>-</span>
          ),
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.assignee`)}`} />
        ),
        accessor: 'assignee',
        Cell: ({ value }: { value: TicketAssignee }) =>
          value ? (
            <EmployeeWithAvatar
              employee={{
                name: value?.name,
                avatarUrl: value?.avatarUrl,
              }}></EmployeeWithAvatar>
          ) : (
            <span>-</span>
          ),
      },
      {
        Header: () => (
          <Trans t={t} i18nKey={`${t(`${i18Prefix}.table.header.status`)}`} />
        ),
        accessor: 'status',
        Cell: ({ value }: { value: TicketStatus }) => {
          return <TicketItemStatus status={value} />;
        },
      },
    ];
    return columnsWithNull.filter((x): x is Column<TableItem> => x !== null);
  }, [isManager, onClick, t]);

  if (!items.length && !hasTicketFilter) {
    return <EmptyTickets emptyReason={'empty'} />;
  }

  if (!items.length && hasTicketFilter) {
    return <EmptyTickets emptyReason={'filtered-empty'} />;
  }

  return (
    <TableContentManual<TableItem>
      columns={columns}
      data={items}
      getRowProps={() => ({ role: 'button' })}
      pageSize={pageSize}
      itemsCount={itemsCount}
      initialSortBy={initialSortBy}
      onSortByChanged={onSortByChanged}
      initialPageIndex={initialPageIndex}
      onPageIndexChanged={onPageIndexChanged}
    />
  );
};

function mapToTableItem(item: TicketsListItem): TableItem {
  return {
    id: item.id,
    status: item.status,
    ticketNumber: item.ticketNumber,
    category: item.category,
    requested: item.requested,
    lastUpdate: item.lastUpdate,
    assignee: item.assignee,
    subject: item.subject,
    employee: item.employee,
  };
}
