import React from 'react';
import ServerError from '../../../../components/ServerError';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import {
  OrganizationLoginOptionType,
  useGetAuthenticationOptionsQuery,
} from '../../__generated__/graphql';
import { useTranslation } from 'react-i18next';
import Loading from './Loading';
import LoginPasswordForm from './LoginPasswordForm';
import LoginWithApiToken from './LoginWithApiToken';
import LoginWithSaml from './LoginWithSaml';
import LoginPasswordFormJwt from './LoginPasswordFormJwt';

const LoginPage: React.FC = () => {
  const { data, loading, error } = useGetAuthenticationOptionsQuery();
  const { t } = useTranslation();

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <ServerError />;
  }

  if ((data.getAuthenticationOptions?.options ?? []).length > 1) {
    const hasPassword = data.getAuthenticationOptions?.options?.some(
      (option) => option.type === OrganizationLoginOptionType.Password,
    );

    return (
      <>
        {hasPassword && (
          <>
            <LoginPasswordForm className="mb-3" />
            <p className="text-muted text-center">{t('signIn.or')}</p>
          </>
        )}
        {!hasPassword && (
          <>
            <h1 className="display-4 text-center mb-3">{t('signIn.title')}</h1>
            <p className="text-muted text-center mb-5">
              {t('signInSaml.multipleProviders')}
            </p>
          </>
        )}
        {data.getAuthenticationOptions?.options?.map((option) => {
          if (option.type === OrganizationLoginOptionType.Saml) {
            return (
              <LoginWithSaml
                key={option.name}
                provider={option.name}
                headless
                className="mb-3"
              />
            );
          }
          return null;
        })}
      </>
    );
  }

  if (data.getAuthenticationOptions?.saml) {
    return (
      <LoginWithSaml provider={data.getAuthenticationOptions?.saml.name} />
    );
  }

  if (data.getAuthenticationOptions?.jwt?.enabled) {
    return <LoginPasswordFormJwt />;
  }
  return <LoginPasswordForm />;
};

const Login: React.FC = () => {
  const { apiToken, jwt } = useQueryParams();

  if (apiToken) {
    return <LoginWithApiToken apiToken={apiToken} />;
  }

  // for jwt beta testing from client side
  if (jwt) {
    return <LoginPasswordFormJwt />;
  }

  return <LoginPage />;
};

export default Login;
