import { Ticket } from './types';
import {
  mapTicketAssignee,
  mapTicketCategory,
  mapTicketEmployee,
  mapTicketSubject,
} from '../mappings';
import {
  TicketSidebarDataFragment,
  TicketVisibility,
} from '../../../../__generated__/graphql';
import { TicketStatus } from '../types';
import { TFunc } from '../../../../../../hooks/useNamespacedTranslation';

export const mapTicketSidebarData = (
  data: TicketSidebarDataFragment | undefined | null,
): Ticket | null => {
  if (!data) return null;

  return {
    id: data.Id,
    status: data.flair__Status__c as TicketStatus,
    ticketNumber: data.flair__Ticket_Number__c,
    details: data.flair__Details__c ? data.flair__Details__c : null,
    category: mapTicketCategory(data.category),
    requested: data.CreatedDate,
    lastUpdate: data.LastModifiedDate,
    assignee: data.assignee ? mapTicketAssignee(data.assignee) : null,
    subject: data.subject ? mapTicketSubject(data.subject) : null,
    closeReason: data.flair__Closing_Reason__c,
    visibility: data.flair__Visibility__c,
    anonymous: data.flair__Anonymous__c,
    employee: mapTicketEmployee(
      data.employee,
      data.flair__Anonymous__c,
      data.flair__Employee__c,
    ),
  };
};

export const getTicketVisibilityText = (
  t: TFunc,
  visibility: string,
  anonymous: boolean,
): string => {
  if (visibility === TicketVisibility.HrEmployee && !anonymous) {
    return t('visibilityOptions.notSharedWithManager');
  }

  if (visibility === TicketVisibility.HrManagerEmployee && !anonymous) {
    return t('visibilityOptions.sharedWithManager');
  }

  return t('visibilityOptions.anonymous');
};
