import React, { useCallback } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from '../../../../../Router';
import { employeePageRoutes } from './routes';
import { useTranslation } from 'react-i18next';
import { useServerFeature } from '../../../../../hooks/useServerFeature';
import { ManagerRole } from '../../../__generated__/graphql';
import { hasAnyRoles } from '../../../context/UserInfo';
import { LMS, useFeature } from '../../../../../context/FeaturesContext';

type Props = {
  employeeId: string;
  managerRolesForEmployee: readonly ManagerRole[];
};

const EmployeeNav: React.FC<Props> = ({
  employeeId,
  managerRolesForEmployee,
}) => {
  const { t } = useTranslation();
  const employeeRoutes = employeePageRoutes();
  type RouteType = (typeof employeeRoutes)[keyof typeof employeeRoutes];

  const isLearningFeatureEnabled = useFeature(LMS);

  const hasAccessToRoute = useCallback(
    (route: RouteType) =>
      hasAnyRoles(
        managerRolesForEmployee,
        route.rolesWithAccessForThatEmployee,
      ),
    [managerRolesForEmployee],
  );

  const isEmployeeNotesVisible =
    useServerFeature('Private_Notes') &&
    hasAccessToRoute(employeeRoutes.employeeNotes);

  const isEmployeeHistoryVisible = hasAccessToRoute(
    employeeRoutes.employeeHistory,
  );

  const isEmployeeInventoryVisible = useServerFeature('Inventory');

  const isEmployeeLearningVisible =
    isLearningFeatureEnabled &&
    hasAccessToRoute(employeeRoutes.employeeLearning);

  return (
    <Nav variant="tabs" className="header-tabs" as="ul">
      {hasAccessToRoute(employeeRoutes.overview) && (
        <Nav.Item as="li">
          <NavLink
            className="nav-link"
            activeClassName="active"
            exact={true}
            to={employeeRoutes.overview.route}
            employeeId={employeeId}>
            {t('employeePage.overview.title')}
          </NavLink>
        </Nav.Item>
      )}
      {hasAccessToRoute(employeeRoutes.absenceCalendar) && (
        <Nav.Item as="li">
          <NavLink
            className="nav-link"
            activeClassName="active"
            exact={true}
            to={employeeRoutes.absenceCalendar.route}
            employeeId={employeeId}>
            {t('employeePage.absenceCalendar.title')}
          </NavLink>
        </Nav.Item>
      )}
      {hasAccessToRoute(employeeRoutes.timeSheetList) && (
        <Nav.Item as="li">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={employeeRoutes.timeSheetList.route}
            employeeId={employeeId}>
            {t('employeePage.timeSheetList.title')}
          </NavLink>
        </Nav.Item>
      )}
      {hasAccessToRoute(employeeRoutes.performanceReview) && (
        <Nav.Item as="li">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={employeeRoutes.performanceReview.route}
            employeeId={employeeId}>
            {t('employeePage.performanceReview.engagementTitle')}
          </NavLink>
        </Nav.Item>
      )}
      {hasAccessToRoute(employeeRoutes.employeeCertificates) && (
        <Nav.Item as="li">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={employeeRoutes.employeeCertificates.route}
            employeeId={employeeId}>
            {t('employeePage.employeeCertificate.title')}
          </NavLink>
        </Nav.Item>
      )}
      {isEmployeeLearningVisible && (
        <Nav.Item as="li">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={employeeRoutes.employeeLearning.route}
            employeeId={employeeId}>
            {t('employeePage.employeeLearning.title')}
          </NavLink>
        </Nav.Item>
      )}
      {hasAccessToRoute(employeeRoutes.employeeDocuments) && (
        <Nav.Item as="li">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={employeeRoutes.employeeDocuments.route}
            employeeId={employeeId}>
            {t('employeePage.employeeDocuments.title')}
          </NavLink>
        </Nav.Item>
      )}
      {isEmployeeHistoryVisible && (
        <Nav.Item as="li">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={employeeRoutes.employeeHistory.route}
            employeeId={employeeId}>
            {t('employeePage.employeeHistory.title')}
          </NavLink>
        </Nav.Item>
      )}
      {isEmployeeNotesVisible && (
        <Nav.Item as="li">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={employeeRoutes.employeeNotes.route}
            employeeId={employeeId}>
            {t('employeePage.employeeNotes.title')}
          </NavLink>
        </Nav.Item>
      )}
      {isEmployeeInventoryVisible && (
        <Nav.Item as="li">
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={employeeRoutes.employeeInventory.route}
            employeeId={employeeId}>
            {t('employeePage.employeeInventory.title')}
          </NavLink>
        </Nav.Item>
      )}
    </Nav>
  );
};

export default EmployeeNav;
