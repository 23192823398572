import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isEqual, uniq } from 'lodash';
import { useUserInfo } from '../apps/dashboard/context/UserInfo';

type Features = {
  features: string[];
};

export const ADD_INVENTORY_ITEM_FEATURE = 'addInventoryItem';
export const INVENTORY_STATUS_FEATURE = 'inventoryStatus';
export const HR_HELP_DESK = 'hrHelpDesk';
export const FLAIR_AI = 'flairAi';
export const LMS = 'lms';

const PARAM = 'ft';

const STORAGE_KEY = 'flair-features';

const FeaturesContext = React.createContext<Features>({
  features: [],
});

export const FeaturesProvider: React.FC = (props) => {
  const { organizationsFeatures } = useUserInfo();
  const [browserLevelFeatures, setBrowserLevelFeatures] = useState<string[]>(
    parseFeatures(localStorage.getItem(STORAGE_KEY)),
  );
  const queryParams = useLocation().search;
  const query: URLSearchParams = useMemo(
    () => new URLSearchParams(queryParams),
    [queryParams],
  );

  useEffect(() => {
    if (!query.has(PARAM)) {
      return;
    }
    const featuresStr = query.get(PARAM) || '';
    const features = parseFeatures(featuresStr);
    if (!isEqual(features, browserLevelFeatures)) {
      localStorage.setItem(STORAGE_KEY, featuresStr);
      setBrowserLevelFeatures(features);
    }
  }, [query, browserLevelFeatures]);

  const mergedFeatures = uniq([
    ...organizationsFeatures.map((item) => item.Id),
    ...browserLevelFeatures,
  ]);

  return (
    <FeaturesContext.Provider value={{ features: mergedFeatures }} {...props} />
  );
};

export const useFeatures = () => React.useContext(FeaturesContext).features;
export const useFeature = (featureId: string) =>
  useFeatures().indexOf(featureId) !== -1;

export const useFlairAiFeature = () => {
  return useFeature(FLAIR_AI);
};

const parseFeatures = (featuresStr: string | null | undefined): string[] =>
  featuresStr?.split(',') ?? [];
