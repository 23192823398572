import { useCallback } from 'react';
import { ManagerReportsType } from '../__generated__/graphql';
import { useFlairLocalStorage } from './useFlairLocalStorage';

export type ManagerReportsMode = 'all' | 'direct';

export function isManagerReportsMode(
  src: string | undefined,
): src is ManagerReportsMode {
  return src === 'all' || src === 'direct';
}

export type ManagerReportsQuery =
  | 'myteam'
  | 'absences'
  | 'pendingTimeEntryChangeRequests'
  | 'timeTrackingControlling'
  | 'projectsTimeTrackingControlling'
  | 'expenses'
  | 'learning';

export type ManagerReportsStoreInfo = Record<
  ManagerReportsQuery,
  ManagerReportsMode | undefined
>;

export const MANAGER_REPORT_STORAGE_KEY = 'manager_reports_mode';

const initialReportsStoreInfo: ManagerReportsStoreInfo = {
  myteam: undefined,
  absences: undefined,
  pendingTimeEntryChangeRequests: undefined,
  timeTrackingControlling: undefined,
  projectsTimeTrackingControlling: undefined,
  expenses: undefined,
  learning: undefined,
};

export const useManagerReportsMode = (
  query: ManagerReportsQuery,
  fallbackReportingMode: ManagerReportsMode = 'all',
): [ManagerReportsMode, (value: ManagerReportsMode) => void] => {
  const [info, storeInfo] = useFlairLocalStorage(
    MANAGER_REPORT_STORAGE_KEY,
    initialReportsStoreInfo,
  );
  const reportsModeStr = info[query];
  const reportsMode: ManagerReportsMode = isManagerReportsMode(reportsModeStr)
    ? reportsModeStr
    : fallbackReportingMode;

  const setReportsMode = useCallback(
    (value: ManagerReportsMode) => {
      storeInfo((prevStoreInfo) => ({
        ...prevStoreInfo,
        [query]: value,
      }));
    },
    [storeInfo, query],
  );

  return [reportsMode, setReportsMode];
};

export const mapManagerReportsType = (
  src: ManagerReportsMode,
): ManagerReportsType => {
  switch (src) {
    case 'all':
      return ManagerReportsType.All;
    case 'direct':
      return ManagerReportsType.Direct;
    default:
      return ManagerReportsType.All;
  }
};
