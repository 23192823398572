import React from 'react';

import FreeSliderInput from './questions/FreeSlider';
import DropdownInput from './questions/Dropdown';
import ScorecardInput from './questions/Scorecard';
import FreeTextInput from './questions/FreeText';
import SingleChoiceInput from './questions/SingleChoice';
import MultipleChoiceInput from './questions/MultipleChoice';
import { ChoiceOption, QuestionnaireType } from '../types';
import { Rating } from './questions/Rating';
import { useEngagementSurveySettings } from '../../../context/engagementSurvey/EngagementSurveySettingsContext';

export type QuestionProps = {
  id: string;
  value: string;
  onChange: (value: string) => void;
  type: QuestionnaireType;
  choices: ReadonlyArray<ChoiceOption>;
  notes: string;
  onChangeNotes: (value: string) => void;
  readOnly: boolean;
  requiredChoices: ReadonlyArray<string>;
  showOptionalComment: boolean;
};

export const QuestionnaireInput: React.FC<QuestionProps> = ({
  id,
  value,
  onChange,
  type,
  choices,
  notes,
  onChangeNotes,
  readOnly,
  showOptionalComment,
  requiredChoices,
}) => {
  const { ratingMin, ratingMax } = useEngagementSurveySettings();
  switch (type) {
    case QuestionnaireType.FreeText: {
      return (
        <FreeTextInput
          id={id}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
        />
      );
    }
    case QuestionnaireType.Rating: {
      return (
        <Rating
          value={value}
          choices={choices.map((item) => item.label)}
          onChange={onChange}
          notes={notes}
          onChangeNotes={onChangeNotes}
          readOnly={readOnly}
          showOptionalComment={showOptionalComment}
          ratingMin={ratingMin}
          ratingMax={ratingMax}
        />
      );
    }
    case QuestionnaireType.MultipleChoice: {
      return (
        <MultipleChoiceInput
          id={id}
          value={value}
          choices={choices}
          onChange={onChange}
          notes={notes}
          onChangeNotes={onChangeNotes}
          readOnly={readOnly}
          showOptionalComment={showOptionalComment}
        />
      );
    }
    case QuestionnaireType.SingleChoice: {
      return (
        <SingleChoiceInput
          id={id}
          value={value}
          choices={choices}
          onChange={onChange}
          notes={notes}
          onChangeNotes={onChangeNotes}
          readOnly={readOnly}
          showOptionalComment={showOptionalComment}
        />
      );
    }
    case QuestionnaireType.FreeSlider: {
      return (
        <FreeSliderInput
          id={id}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          notes={notes}
          onChangeNotes={onChangeNotes}
          showOptionalComment={showOptionalComment}
        />
      );
    }
    case QuestionnaireType.Dropdown: {
      return (
        <DropdownInput
          id={id}
          value={value}
          choices={choices}
          onChange={onChange}
          notes={notes}
          onChangeNotes={onChangeNotes}
          readOnly={readOnly}
          showOptionalComment={showOptionalComment}
        />
      );
    }
    case QuestionnaireType.Scorecard: {
      return (
        <ScorecardInput
          id={id}
          value={value}
          choices={choices}
          onChange={onChange}
          notes={notes}
          onChangeNotes={onChangeNotes}
          readOnly={readOnly}
          showOptionalComment={showOptionalComment}
          requiredChoices={requiredChoices}
        />
      );
    }
    //YesOrNo type is currently is not supported in the questionnaire itself we are using it for employee satisfaction survey only, but maybe we will have this type in the future
    case QuestionnaireType.YesNo:
    case QuestionnaireType.Unknown: {
      return null;
    }
  }
};
