import React from 'react';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';
import { Ticket } from '../types';
import { TicketItemStatus } from '../../Table/StatusCell/TicketItemStatus';
import { EmployeeWithAvatar } from '../../../../../components/Employee';
import {
  formatRelative,
  parseDate,
} from '../../../../../../../utils/dateUtils';
import { TicketStatus } from '../../../../../__generated__/graphql';
import { getTicketVisibilityText } from '../mappings';

type Props = {
  ticket: Ticket;
  onTicketClose: (id: string) => void;
  isManager: boolean;
};

const i18Prefix = 'support.tickets.sidebarModal.information';
export const TicketsInformationTab: React.FC<Props> = ({
  ticket,
  onTicketClose,
  isManager,
}) => {
  const t = useNamespacedTranslation(i18Prefix);
  return (
    <InfoFieldsWrapper>
      <div className="d-flex flex-column gap-4">
        {isManager && (
          <div className="d-flex flex-column gap-2">
            <h4 className="card-header-title">{t('employee')}</h4>
            <EmployeeWithAvatar
              employee={{
                name: ticket.employee?.name,
                avatarUrl: ticket.employee?.avatarUrl,
              }}></EmployeeWithAvatar>
          </div>
        )}
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('subject')}</h4>
          <h4 className="card-header-title h4-regular">
            {ticket.subject?.label ?? ticket.subject?.name ?? '-'}
          </h4>
        </div>
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('details')}</h4>
          {ticket.details ? (
            <h4 className="card-header-title h4-regular">{ticket.details}</h4>
          ) : (
            <span>-</span>
          )}
        </div>
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('category')}</h4>
          <h4 className="card-header-title h4-regular">
            {ticket.category.label
              ? ticket.category.label
              : ticket.category.name}
          </h4>
        </div>
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('assignee')}</h4>
          {ticket.assignee?.id ? (
            <EmployeeWithAvatar
              employee={{
                name: ticket.assignee.name,
                avatarUrl: ticket.assignee.avatarUrl,
              }}></EmployeeWithAvatar>
          ) : (
            <span>-</span>
          )}
        </div>
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('ticketNumber')}</h4>
          <h4 className="card-header-title h4-regular">
            {ticket.ticketNumber}
          </h4>
        </div>
        {!isManager && (
          <div className="d-flex flex-column gap-2">
            <h4 className="card-header-title">{t('visibility')}</h4>
            <h4 className="card-header-title h4-regular">
              {getTicketVisibilityText(t, ticket.visibility, ticket.anonymous)}
            </h4>
          </div>
        )}
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('lastUpdate')}</h4>
          {ticket.lastUpdate ? (
            <h4 className="card-header-title h4-regular">
              {formatRelative(parseDate(ticket.lastUpdate))}
            </h4>
          ) : (
            <span>-</span>
          )}
        </div>
        <div className="d-flex flex-column gap-2">
          <h4 className="card-header-title">{t('status')}</h4>
          <TicketItemStatus status={ticket.status} />
        </div>
        {ticket.closeReason && (
          <div className="d-flex flex-column gap-2">
            <h4 className="card-header-title">{t('closeReason')}</h4>
            <h4 className="card-header-title h4-regular">
              {ticket.closeReason}
            </h4>
          </div>
        )}
      </div>
      {ticket.status !== TicketStatus.Closed && (
        <div className="bg-white w-100 position-fixed pe-5 pb-3 bottom-0">
          <Button
            className="w-100"
            variant="outline-primary"
            onClick={() => onTicketClose(ticket.id)}>
            {t('buttons.closeTicket')}
          </Button>
        </div>
      )}
    </InfoFieldsWrapper>
  );
};

const InfoFieldsWrapper = styled.div`
  overflow-y: auto;
  max-height: 40rem;
  padding-bottom: 8rem;
`;
