import { EmployeeWithAvatarAndPopup_ColleagueFragment } from '../../__generated__/graphql';

export const allCourseStatuses = [
  'NOT_STARTED',
  'IN_PROGRESS',
  'COMPLETED',
] as const;

export type CourseStatus = (typeof allCourseStatuses)[number];

export function isCourseStatus(status: unknown): status is CourseStatus {
  return (
    typeof status === 'string' && allCourseStatuses.includes(status as any)
  );
}

export type CourseItem = {
  id: string;
  employeeId: string;
  name: string;
  status: CourseStatus;
  completeProgress: number;
  totalSeconds: number;
  enrolled: Date | null;
  completed: Date | null;
  employee: EmployeeWithAvatarAndPopup_ColleagueFragment | null; // required for employeeColumnsVisible
};

export type Filter = {
  search: string | null;
  status: CourseStatus | null;
  employeeId?: string | null;
};
