import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/PageHeader';
import { useFlairBreadcrumbHook } from '../../../../../hooks/useFlairBreadcrumbHook';
import PageHeaderNav, { TabItem } from '../../../components/PageHeaderNav';
import { useUserInfo } from '../../../context/UserInfo';
import { routes } from '../../../routes';
import { routes as localRoutes } from './routes';
import { Route, Switch } from 'react-router-dom';
import RedirectToHome from '../../../components/RedirectToHome';

export const LearningPage: React.FC = () => {
  const { t } = useTranslation();
  const userInfo = useUserInfo();

  useFlairBreadcrumbHook([
    { label: t('navigation.menuItems.personalDevelopment.title') },
    { label: t('lms.learningPage.title') },
  ]);

  const activeTabs: (TabItem | null)[] = userInfo.isActive
    ? [
        {
          label: t('lms.courses.title'),
          route: routes.performanceReviewLearning.route,
        },
      ]
    : [];

  return (
    <>
      <PageHeader title={t('lms.learningPage.title')}>
        <PageHeaderNav
          tabItems={activeTabs.filter((x): x is TabItem => x !== null)}
        />
      </PageHeader>
      <Content />
    </>
  );
};

const Content: React.FC = () => (
  <Switch>
    {Object.values(localRoutes()).map(({ render, route }, i) => (
      <Route
        key={i}
        exact={true}
        path={route.template()}
        render={() => render()}
      />
    ))}
    <Route path="*">
      <RedirectToHome />
    </Route>
  </Switch>
);
