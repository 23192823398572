import { getErrorMessage } from '../../../../utils/errorUtils';

export type GetGeolocationStatus =
  | 'success'
  | 'geolocationNotSupported'
  | 'unknownError'
  // From https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
  | 'permissionDenied'
  | 'positionUnavailable'
  | 'timeout';

export type GetGeolocationResult = {
  status: GetGeolocationStatus;
  coordinates?: GeolocationCoordinates;
  error?: string;
};

export async function getGeolocation(): Promise<GetGeolocationResult> {
  return new Promise((resolve) => {
    if (!navigator.geolocation) {
      resolve({
        status: 'geolocationNotSupported',
      });
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            status: 'success',
            coordinates: position.coords,
          });
        },
        (error) => {
          if (error instanceof GeolocationPositionError) {
            resolve(mapGeolocationError(error));
          } else {
            resolve({
              status: 'unknownError',
              error: getErrorMessage(error),
            });
          }
        },
        {
          enableHighAccuracy: true,
        },
      );
    }
  });
}

const mapGeolocationError = (
  error: GeolocationPositionError,
): GetGeolocationResult => {
  return {
    status: mapErrorStatus(error),
    error: error.message,
  };
};

const mapErrorStatus = (
  error: GeolocationPositionError,
): GetGeolocationStatus => {
  switch (error.code) {
    case GeolocationPositionError.PERMISSION_DENIED:
      return 'permissionDenied';
    case GeolocationPositionError.POSITION_UNAVAILABLE:
      return 'positionUnavailable';
    case GeolocationPositionError.TIMEOUT:
      return 'timeout';
    default:
      return 'unknownError';
  }
};
