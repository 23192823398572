import styled from '@emotion/styled';
import { compact } from 'lodash';
import React from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../../theme';
import {
  ComparedFeedbackAnswer,
  ComparedFeedbackAnswerType,
} from '../../hooks/useComparedFeedbackSectionsMapper';
import FreeSlider from '../EmployeeFeedbackQuestionnaire/EmployeeFeedbackInput/questions/FreeSlider';
import { IScorecard } from '../EmployeeFeedbackQuestionnaire/types';
import {
  convertToScorecards,
  getScore,
} from '../EmployeeFeedbackQuestionnaire/utils';
import ScorecardGroup from '../ScorecardGroup';
import FlairIcon from '../../../../atomic/atoms/FlairIcon';
import { Rating } from '../Questionnaire/QuestionnaireInput/questions/Rating';
import { useNamespacedTranslation } from '../../../../hooks/useNamespacedTranslation';
import { Nullable } from '../../../../utils/nullable';
import { useFeedbackCycleSettings } from '../../context/feedbackCycle/FeedbackCycleSettingsContext';

type Props = {
  answer: ComparedFeedbackAnswer;
  employeeName: Nullable<string>;
  side: 'right' | 'left';
};

const FeedbackAnswerContainer = styled(Col)({
  display: 'flex',
  flexDirection: 'column',
});

const Label: React.FC<{ name: string }> = ({ name }) => {
  const { t } = useTranslation();
  return (
    <label className="mb-2">
      {t('feedbackMeeting.employeeAnswerLabel', {
        employeeName: name,
        interpolation: { escapeValue: false },
      })}
    </label>
  );
};

const EmptyAnswerContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: Theme.color.gray5,
  borderRadius: Theme.border.radius,
  height: '50px',
});

const HiddenAnswerSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '50px',
  height: '100%',
  backgroundColor: Theme.color.gray5,
  borderRadius: Theme.border.radius,
  color: Theme.color.gray2,
});

const HiddenText = (props: React.HTMLAttributes<HTMLSpanElement>) => (
  <span {...props} />
);

const EmptyAnswer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <EmptyAnswerContainer>
      {t('feedbackMeeting.emptyAnswer')}
    </EmptyAnswerContainer>
  );
};

const AnswerNote = styled.span<Pick<Props, 'side'>>(
  {
    padding: '0.5rem',
    whiteSpace: 'pre-wrap',
  },
  ({ side }) => {
    switch (side) {
      case 'left': {
        return {
          borderLeft: `3px solid ${Theme.color.blue5}`,
        };
      }
      case 'right': {
        return {
          textAlign: 'right',
          borderRight: `3px solid ${Theme.color.blue5}`,
        };
      }
    }
  },
);

const Answer = styled.span({
  backgroundColor: Theme.color.bgBlue,
  padding: '0.5rem',
  borderRadius: '0.5rem',
  whiteSpace: 'pre-wrap',
});

const Option = styled(Answer.withComponent('dd'))<{ active: boolean }>(
  ({ active }) =>
    !active && {
      color: Theme.color.blue4,
      border: `1px dashed ${Theme.color.blue5}`,
      backgroundColor: 'transparent',
    },
);

type OptionsProps = {
  choices: ReadonlyArray<string>;
  isSelected: (choice: string) => boolean;
};

type ScorecardProps = {
  choices: ReadonlyArray<string>;
  value: string;
};

const ScorecardAnswer: React.FC<ScorecardProps> = ({ choices, value }) => {
  const scorecards: IScorecard[] = compact(convertToScorecards(value || ''));

  return (
    <>
      {choices.map((choice) => (
        <div className="py-2" key={choice}>
          <div>
            <small>
              <span className="pe-1">{choice}</span>
            </small>
          </div>
          <div className="pt-1">
            <ScorecardGroup
              selectedScore={getScore(scorecards, choice)}
              name={choice}
              disabled={true}
              loading={false}
              isComplete={false}
            />
          </div>
        </div>
      ))}
    </>
  );
};

const Options: React.FC<OptionsProps> = ({ choices, isSelected }) => (
  <dl>
    {choices.map((choice) => (
      <Option key={choice} active={isSelected(choice)}>
        {choice}
      </Option>
    ))}
  </dl>
);

export const FeedbackAnswer: React.FC<Props> = ({
  answer,
  employeeName,
  side,
}) => {
  const { ratingMin, ratingMax } = useFeedbackCycleSettings();
  const employeeNameLabel = employeeName && <Label name={employeeName} />;
  switch (answer.type) {
    case ComparedFeedbackAnswerType.FREE_TEXT: {
      return (
        <FeedbackAnswerContainer>
          {employeeNameLabel}
          {answer.value ? <Answer>{answer.value}</Answer> : <EmptyAnswer />}
        </FeedbackAnswerContainer>
      );
    }
    case ComparedFeedbackAnswerType.SINGLE_CHOICE: {
      return (
        <FeedbackAnswerContainer>
          {employeeNameLabel}
          <Options
            choices={answer.choices}
            isSelected={(choice) => answer.value === choice}
          />
          {answer.note && <AnswerNote side={side}>{answer.note}</AnswerNote>}
        </FeedbackAnswerContainer>
      );
    }
    case ComparedFeedbackAnswerType.MULTIPLE_CHOICE: {
      return (
        <FeedbackAnswerContainer>
          {employeeNameLabel}
          <Options
            choices={answer.choices}
            isSelected={(choice) => !!answer.value?.includes(choice)}
          />
          {answer.note && <AnswerNote side={side}>{answer.note}</AnswerNote>}
        </FeedbackAnswerContainer>
      );
    }
    case ComparedFeedbackAnswerType.RATING: {
      return (
        <FeedbackAnswerContainer>
          {employeeNameLabel}

          {answer.value ? (
            <Rating
              onChangeNotes={() => null}
              onChange={() => null}
              notes={answer.note ? answer.note : ''}
              choices={answer.choices}
              readOnly={true}
              value={answer.value}
              ratingMin={ratingMin}
              ratingMax={ratingMax}
            />
          ) : (
            <EmptyAnswer />
          )}
          {answer.note && <AnswerNote side={side}>{answer.note}</AnswerNote>}
        </FeedbackAnswerContainer>
      );
    }
    case ComparedFeedbackAnswerType.FREE_SLIDER: {
      return (
        <FeedbackAnswerContainer>
          {employeeNameLabel}

          {answer.value ? (
            <FreeSlider
              id={answer.id}
              value={answer.value || ''}
              readOnly={true}
              notes={''}
              showOptionalComment={false}
              onChangeNotes={() => {}}
            />
          ) : (
            <EmptyAnswer />
          )}
          {answer.note && <AnswerNote side={side}>{answer.note}</AnswerNote>}
        </FeedbackAnswerContainer>
      );
    }
    case ComparedFeedbackAnswerType.SCORECARD: {
      return (
        <FeedbackAnswerContainer>
          {employeeNameLabel}
          {answer.value ? (
            <ScorecardAnswer value={answer.value} choices={answer.choices} />
          ) : (
            <EmptyAnswer />
          )}
          {answer.note && <AnswerNote side={side}>{answer.note}</AnswerNote>}
        </FeedbackAnswerContainer>
      );
    }
    case ComparedFeedbackAnswerType.DROPDOWN: {
      return (
        <FeedbackAnswerContainer>
          {employeeNameLabel}
          <Options
            choices={answer.choices}
            isSelected={(choice) => answer.value === choice}
          />
          {answer.note && <AnswerNote side={side}>{answer.note}</AnswerNote>}
        </FeedbackAnswerContainer>
      );
    }
    case ComparedFeedbackAnswerType.YES_NO: {
      return <></>;
    }
  }
};

export const HiddenFeedbackAnswer: React.FC<{
  employeeName: string;
}> = ({ employeeName }) => {
  const { t } = useTranslation();
  return (
    <FeedbackAnswerContainer>
      <Label name={employeeName} />
      <HiddenAnswerSection>
        <FlairIcon icon="eye-off-outline" />
        <HiddenText className="ms-2">
          {t('feedbackMeeting.hiddenAnswer')}
        </HiddenText>
      </HiddenAnswerSection>
    </FeedbackAnswerContainer>
  );
};

export const NotRequiredFeedbackAnswer: React.FC<{
  employeeName?: string;
}> = ({ employeeName }) => {
  const { t } = useTranslation();
  return (
    <FeedbackAnswerContainer>
      {employeeName && <Label name={employeeName} />}
      <HiddenAnswerSection>
        <FlairIcon icon="lock-open-outline" />
        <HiddenText className="ms-2">
          {t('feedbackMeeting.notRequiredAnswer')}
        </HiddenText>
      </HiddenAnswerSection>
    </FeedbackAnswerContainer>
  );
};

export const NotSubmittedAnswer: React.FC<{
  employeeName?: string;
}> = ({ employeeName }) => {
  const t = useNamespacedTranslation('employeeFeedbackQuestionnaire');
  return (
    <FeedbackAnswerContainer>
      {employeeName && <Label name={employeeName} />}
      <EmptyAnswerContainer>{t('notSubmittedYet')}</EmptyAnswerContainer>
    </FeedbackAnswerContainer>
  );
};
