import React from 'react';
import Button from '../../../../../components/button';
import { useGotoCourseUrlMutation } from '../../../__generated__/graphql';
import { useToasts } from '../../../../../context/Toast';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';

type Props = {
  id: string;
};

export const GotoCourseButtonConnected: React.FC<Props> = ({ id }) => {
  const t = useNamespacedTranslation('lms.coursesTable');
  const [getUrl, { loading }] = useGotoCourseUrlMutation();
  const { addError } = useToasts();

  const handleClick = () => {
    getUrl({
      variables: {
        id,
      },
    })
      .then((resp) => {
        const url = resp.data?.courses.gotoCourseUrl;
        if (!url) {
          return;
        }
        window.open(url, '_blank');
      })
      .catch((e) => {
        addError(e.message);
      });
  };

  return (
    <Button onClick={handleClick} isProcessing={loading} label={t('goto')} />
  );
};
