import { SkillsManagerPage } from '../../pages/Skills';
import { ManagerGoalsPage } from '../Goals';
import { ManagerLearningPage } from '../Learning';
import ReviewsList from '../ReviewsList';
import { managerRoutes } from '../routes';

export const routes = () => ({
  reviews: {
    route: managerRoutes.reviews.route,
    component: ReviewsList,
  },
  goals: {
    route: managerRoutes.goals.route,
    component: ManagerGoalsPage,
  },
  skills: {
    route: managerRoutes.skills.route,
    component: SkillsManagerPage,
  },
  learning: {
    route: managerRoutes.learning.route,
    component: ManagerLearningPage,
  },
});
